import React from 'react'
import PageContainer from '../../components/layouts/PageContainer';
import BreadCrumb from '../../components/elements/BreadCrumb';
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import About1 from "../../assets/static/img/about1.png"
import About2 from "../../assets/static/img/about2.png"
import About3 from "../../assets/static/img/about3.png"
import About4 from "../../assets/static/img/about4.png"
import Slider from 'react-slick';
import { Helmet } from 'react-helmet';


const AboutUs = () => {
    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "About us",
        },
    ];
    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;

    const carouselFullwidth = {
        dots: false,
        infinite: true,
        speed: 750,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoPlay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                    arrows: false,
                },
            },
        ],
    };

    return (
        <div>
            <Helmet>
                <title>W.Hanson Group - A little about the W.Hanson Group and why we are the perfect merchant to use for all your building needs.</title>
                <meta name="keywords" Content="About W.Hanson Timber & Builders Merchant, Timber Merchant, Builders Merchant, Timber Merchants, Builders merchants, timber supplies, building supplies, merchant, free delivery , mdf mouldings, skirting , architrave, machined, diy, Timber Merchant Southall, Builders merchant greenford, Timber Merchant Greenford, timber merchant ealing, builders merchant ealing, ealing timber, greenford timber, southall timber, Builders Merchant Southall, Timber Merchant West London, Builders Merchant West London, Timber Merchant Middlesex, Builders Merchant Middlesex, Timber Merchants Middlesex, Builders merchants Middlesex, Timber supplies London Middlesex, Building Supplies London Middlesex"></meta>
                <meta name="description" Content="Find out more about what drives W.Hanson ti be the premier Timber merchant in London. W.Hanson Timber & Builders Merchants have been servicing the trade for over 50 years, and we have an unrivalled knowledge of timber." />

            </Helmet>
            <PageContainer header={headers} footer={footer} title="Register">
                <div className="ps-page--single">
                    <img src="http://w-hanson.co.uk/images/Iron%20Bridge.jpg" height="300" style={{ width: "100%", objectFit: "cover" }} alt="" />
                    <BreadCrumb breacrumb={breadCrumb} />
                    <div className="ps-section--custom">
                        <div className="container">
                            <div>
                                <div className='row align-items-center'>
                                    <div className='col-md-6 bd-width-img'>
                                        <div className='w-80 position-relative'>
                                            <div className='bd-about-border'></div>
                                            <div className='bd-img-set'>
                                                <img src="http://www.w-hanson.co.uk/images/Warehouse%20(6).JPG" alt='No Img' height='500' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 about-text'>
                                        <h1>About Us</h1>
                                        <p><i className='icon-arrow-right pr-10'></i>
                                            W.Hanson Timber and Builders Merchants have been established in the West London area for over 60 years, servicing both trade and the general public.</p>
                                        <p><i className='icon-arrow-right pr-10'></i>
                                            Operating from 2 sites, W.Hanson offers a wide range of panel products, softwoods, hardwoods, MDF mouldings and heavyside goods from our comprehensive stocks. In addition we have the technology and skills to match existing moulded patterns, be it small or large quantities.</p>
                                        <p><i className='icon-arrow-right pr-10'></i>
                                            Each depot runs its own transport service, which enables us to offer a next day delivery on most products. Although based in West London, we offer a delivery service to the whole of London and beyond, spanning as far as Essex, Northamptonshire, and Gloucestershire etc.</p>
                                        <p><i className='icon-arrow-right pr-10'></i>As a private independent company we are able to maintain a competitive edge in a variety of products, and import timber and panel products ourselves to reduce costs further. At our Southall branch we have a 2 acre site and also an off the premises 10 000ft2 warehouse where the bulk of our panel products are stored, which enables us to succeed where others may fail in getting that urgent delivery to site. And because we are a private independent company, you will receive the personal touch, which can so often be lost when dealing with the large multi-national companies.</p>
                                        <p><i className='icon-arrow-right pr-10'></i>It has always been a company policy that no job is too large or too small. Since trading began, we have catered for all types of customer, ranging from DIY enthusiast, to the jobbing builder, and have now been dealing with many of the large housebuilding companies for several years, whereby our service has tended to be the deciding factor in securing the business.</p>
                                        <p><i className='icon-arrow-right pr-10'></i>So for all of your building needs, however large or small, please contact our sales staff for friendly advice and free quotations, and enjoy the service that we have prided ourselves on over the last 60 years.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>


        </div >
    )
}

export default AboutUs