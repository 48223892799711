import React from 'react'

const ProductDescription = ({description}) => {
    return (
        <div>
            <div className="ps-document">
                <p style={{color:"black", padding:'0px 20px'}}>
                    {description}
                </p>
            </div>
        </div>
    )
}

export default ProductDescription