import { Input, notification } from 'antd'
import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import Loader from '../Loader'

const Address = ({ states, verticalTab }) => {

    const [isLoading, setLoading] = useState(false)
    const [billing_country, setBillingCountry] = useState('United Kingdom')
    const [billing_state, setBillingstate] = useState('')
    const [billing_city, setBillingCity] = useState('')
    const [billing_code, setBillingCode] = useState('')
    const [billing_address, setBillingAddress] = useState('')
    const [shipping_country, setshippingCountry] = useState('United Kingdom')
    const [shipping_state, setshippingstate] = useState('')
    const [shipping_city, setshippingCity] = useState('')
    const [shipping_code, setshippingCode] = useState('')
    const [shipping_address, setShippingAddress] = useState('')
    const [city_ship_option, setCityShipOption] = useState([])
    const [city_option, setCityOption] = useState([])

    const onChangeState = async (e) => {
        setLoading(true)
        setBillingstate(e.target.options[e.target.selectedIndex].id);
        try {
            await axios({
                method: "get",
                url: `https://whanson.ztpl.net/backend/api/v2/cities/${e.target.value}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then(res => {
                setCityOption(res.data.cities)
                setLoading(false)
            })
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const onChangeShippingState = async (e) => {
        setLoading(true)
        setshippingstate(e.target.options[e.target.selectedIndex].id);
        try {
            await axios({
                method: "get",
                url: `https://whanson.ztpl.net/backend/api/v2/cities/${e.target.value}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then(res => {
                setCityShipOption(res.data.cities)
                setLoading(false)
            })
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (verticalTab == 3) {
            onFectUserData();
        }
    }, [verticalTab])


    const onFectUserData = async () => {
        setLoading(true);
        try {
            await axios({
                method: "get",
                url: `https://whanson.ztpl.net/backend/api/v2/users/${localStorage.getItem("auth_id")}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then(async res => {
                const billing_address = JSON.parse(res.data.users[0]?.billing_address)
                const shipping_address = JSON.parse(res.data.users[0]?.shipping_address)
                setBillingAddress(billing_address?.address)
                setShippingAddress(shipping_address?.address)
                setBillingstate(billing_address?.state)
                setshippingstate(shipping_address?.state)
                setBillingCode(billing_address.postal_code)
                setshippingCode(shipping_address.postal_code)
                setBillingCity(billing_address.city)
                setshippingCity(shipping_address.city)

                const BillStateId = states.filter((obj => obj.name == billing_address?.state))[0].id
                const ShipStateId = states.filter((obj => obj.name == shipping_address?.state))[0].id


                await axios({
                    method: "get",
                    url: `https://whanson.ztpl.net/backend/api/v2/cities/${BillStateId}`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                }).then(res => {
                    setCityOption(res.data.cities)
                    setLoading(false)
                })

                await axios({
                    method: "get",
                    url: `https://whanson.ztpl.net/backend/api/v2/cities/${ShipStateId}`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                }).then(res => {
                    setCityShipOption(res.data.cities)
                    setLoading(false)
                })


                setLoading(false)
            })
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }


    const onUpdateAddress = async (e) => {
        let ShippingAddress = { address: shipping_address, country: shipping_country, state: shipping_state, city: shipping_city, postal_code: shipping_code }

        let BillingAddress = { address: billing_address, country: billing_country, state: billing_state, city: billing_city, postal_code: billing_code }

        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('user_id', localStorage.getItem("auth_id"))
        formData.append('shipping_address', JSON.stringify(ShippingAddress))
        formData.append('billing_address', JSON.stringify(BillingAddress))
        try {
            await axios({
                method: "post",
                url: 'https://whanson.ztpl.net/backend/api/v2/address',
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then(res => {

                notification.open({
                    message: (
                        <div className="d-flex">
                            <div className="pr-20">
                                <i className="fa fa-check" style={{ color: "#4bb543" }}></i>
                            </div>
                            <div>Address Updated Successfully</div>
                        </div>
                    ),
                    description: "Please verify and log in to your account.",
                });
                setLoading(false)
            })
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    return (
        <div>
            {isLoading && (<Loader />)}
            <div className="ps-section--account-setting">
                <div className="ps-section__content">
                    <form onSubmit={onUpdateAddress}>
                        <div className="row">
                            <div className="col-md-12 col-12 mb-30">
                                <figure className="ps-block--address">
                                    <figcaption>
                                        Billing address
                                    </figcaption>
                                    <div className="ps-block__content">

                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <select className="form-control" required value={billing_country} onChange={(e) => setBillingCountry(e.target.value)}>
                                                        <option value=''>Select Country *</option>
                                                        <option value='United Kingdom'>United Kingdom</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <select className="form-control" id='mySelect' required onChange={onChangeState}>
                                                        {states.map((obj, i) => {
                                                            return (
                                                                <option selected={'Argyll' == obj.name} value={obj.id} id={obj.name} key={i}>{obj.name}</option>
                                                            )
                                                        })}

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <select className="form-control" required onChange={(e) => setBillingCity(e.target.value)}>
                                                        <option value=''>Select City *</option>
                                                        {city_option.map((obj, i) => {
                                                            return (
                                                                <option selected={obj.name == billing_city} value={obj.name} key={i}>{obj.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <Input
                                                        className="form-control"
                                                        type="postalCode"
                                                        placeholder="Postal Code *"
                                                        required
                                                        value={billing_code}
                                                        onChange={(e) => setBillingCode(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder="Address *"
                                                required
                                                value={billing_address}
                                                onChange={(e) => setBillingAddress(e.target.value)}
                                            />
                                        </div>

                                    </div>
                                </figure>
                            </div>
                            <div className="col-md-12 col-12">
                                <figure className="ps-block--address">
                                    <figcaption>
                                        Shipping address
                                    </figcaption>
                                    <div className="ps-block__content">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <select className="form-control" required value={shipping_country} onChange={(e) => setshippingCountry(e.target.value)}>
                                                        <option value=''>Select Country *</option>
                                                        <option value='United Kingdom'>United Kingdom</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <select className="form-control" id='mySelect' onChange={onChangeShippingState} required>
                                                        {states.map((obj, i) => {
                                                            return (
                                                                <option selected={shipping_state == obj.name} value={obj.id} id={obj.name} key={i}>{obj.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <select className="form-control" required onChange={(e) => setshippingCity(e.target.value)} >
                                                        <option value=''>Select City *</option>
                                                        {city_ship_option.map((obj, i) => {
                                                            return (
                                                                <option selected={obj.name == shipping_city} value={obj.name} key={i}>{obj.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <Input
                                                        className="form-control"
                                                        type="postalCode"
                                                        placeholder="Postal Code *"
                                                        required
                                                        value={shipping_code}
                                                        onChange={(e) => setshippingCode(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder="Address *"
                                                required
                                                value={shipping_address}
                                                onChange={(e) => setShippingAddress(e.target.value)}
                                            />
                                        </div>

                                    </div>
                                </figure>
                            </div>
                        </div>
                        <div className="form-group submit mt-20">
                            <button className="ps-btn bg-blue color-white">
                                Update Address
                            </button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    )
}

export default Address