import React, { useEffect, useState } from "react";
import PageContainer from "../../components/layouts/PageContainer";
import BreadCrumb from "../../components/elements/BreadCrumb";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import { Radio } from "antd";
import { Link, useNavigate } from "react-router-dom";

const Payment = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (
          !localStorage.getItem("auth_id") &&
          !localStorage.getItem("auth_name")
        ) {
          navigate("/users/login");
        }
      }, []);

  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Payment",
    },
  ];
  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;

  const [method, setMethod] = useState(1);

  function handleChangeMethod(e) {
    setMethod(e.target.value); //e.target.value
  }

  return (
    <div>
      <PageContainer header={headers} footer={footer} title="Register">
        <div className="ps-page--my-account">
          <BreadCrumb breacrumb={breadCrumb} />
          <div className="ps-checkout ps-section--shopping pt-60">
            <div className="container">
              <div
                className="ps-section__header "
                style={{ paddingBottom: "50px" }}
              >
                <h1 style={{ fontSize: "30px", textAlign: "left" }}>
                  Payment Information
                </h1>
              </div>
              <div className="ps-section__content">
                <div className="row">
                  <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                    <div className="ps-block--shipping">
                      <div className="ps-block__panel">
                        <figure>
                          <small>Contact</small>
                          <p>test@gmail.com</p>
                          <Link href="/account/checkout">
                            <a>Change</a>
                          </Link>
                        </figure>
                        <figure>
                          <small>Ship to</small>
                          <p>2015 South Street, Midland, Texas</p>
                          <Link href="/account/checkout">
                            <a>Change</a>
                          </Link>
                        </figure>
                      </div>
                      <h4>Shipping Method</h4>
                      <div className="ps-block__panel">
                        <label for="home" className="w-100">
                          <div className="delivery-type">
                            <Radio
                              id="home"
                              type="radio"
                              width="15"
                              height="15"
                            >
                              Home Delivery
                            </Radio>
                          </div>
                        </label>
                      </div>
                      <h4>Payment Methods</h4>
                      <div className="ps-block--payment-method">
                        <div className="ps-block__header">
                          <Radio.Group
                            onChange={(e) => handleChangeMethod(e)}
                            value={method}
                          >
                            <Radio value={1}>Visa / Master Card</Radio>
                            <Radio value={2}>Paypal</Radio>
                          </Radio.Group>
                        </div>
                        <div className="ps-block__content">
                          {method === 1 ? (
                            <div className="ps-block__tab">
                              <div className="form-group">
                                <label>Card Number</label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="form-group">
                                <label>Card Holders</label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="row">
                                <div className="col-sm-4 col-4">
                                  <div className="form-group">
                                    <label>Expiration Date (MM/YY)</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="01/21"
                                    />
                                  </div>
                                </div>
                                <div className=" col-sm-4 col-4">
                                  <div className="form-group">
                                    <label>CVV</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <button className="ps-btn ps-btn--fullwidth bg-blue color-white">
                                  Submit
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className="ps-block__tab">
                              <a
                                className="ps-btn bg-blue color-white"
                                href="https://www.paypal.com/"
                                target="_blank"
                              >
                                Process with Paypal
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="ps-block__footer">
                        <Link to="/checkout/deliry_info">
                          <a>
                            <i className="icon-arrow-left mr-2"></i>
                            Return to shipping
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 ">
                    <div className="ps-form__orders">
                      <h3>Your order</h3>
                      <div className="ps-block--checkout-order">
                        <div className="ps-block__content   ">
                          <figure className="mb-20">
                            <figcaption>
                              <strong>Product</strong>
                              <strong>total</strong>
                            </figcaption>
                          </figure>
                          <figure className="ps-block__items">
                            <div className="d-flex justify-space-between">
                              <a className="col-9 ">
                                <strong>
                                  OB1 Multi Surface Sealant & Adhesive
                                  <span>x 1</span>
                                </strong>
                              </a>
                              <a className="col-2 ">
                                <small>£111.12</small>
                              </a>
                            </div>
                          </figure>
                          <figure>
                            <figcaption>
                              <strong>Subtotal</strong>
                              <small>
                                <strong>£111.12</strong>
                              </small>
                            </figcaption>
                          </figure>
                          <figure>
                            <figcaption>
                              <strong>Shipping Fee</strong>
                              <small>
                                <strong>£20.00</strong>
                              </small>
                            </figcaption>
                          </figure>
                          <figure className="ps-block__total mb-0 pb-0 border-bottom-none">
                            <h3>
                              Total
                              <strong>£131.12</strong>
                            </h3>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default Payment;
