import React from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../../../assets/static/img/w-hanson-logo-white.png";

const FooterWidgets = () => (
    <div className="ps-footer__widgets justify-space-between">
        <aside className="widget widget_footer widget_contact-us ">
            <div className="widget_content">
                <div>
                    <img src={Logo} className='mb-25' height='105' />
                </div>
                <form className="ps-form--newletter" action="#" method="get">
                    <div className="form-group--nest"><input className="form-control" type="text" placeholder="Email Address" /><button className="ps-btn bg-black color-white">Subscribe</button></div>
                </form>
            </div>
        </aside>
        <aside className="widget widget_footer">
            <h4 className="widget-title">Contact Info </h4>
            <ul className="ps-list--link">
                <li>
                    <p className='color-white'><strong>Address :</strong> 92-108 Greenford Road, Harrow, <br />Middlesex, HA1 3QL</p>
                </li>
                <li>
                    <a className='color-white no-hover' href='callto:020 8864 0822'><strong>Phone :</strong> 020 8864 0822</a>
                </li>
                <li>
                    <a className='color-white no-hover' href='mailto:websales@w-hanson.co.uk'><strong>Email :</strong> websales@w-hanson.co.uk</a>
                </li>
            </ul>
        </aside>
        <aside className="widget widget_footer">
            <h4 className="widget-title">Quick Links </h4>
            <ul className="ps-list--link">
                <li>
                    <Link to="/bespoke-machine-service" className='color-white no-hover'>
                        Bespoke Machine Services
                    </Link>
                </li>
                <li>
                    <Link to="/about-us" className='color-white no-hover'>
                        About Us
                    </Link>
                </li>
                <li>
                    <Link to="/Contact-W.Hanson-for-all-your-timber-&-building-requiremetns.html" className='color-white no-hover'>
                        Contact Us
                    </Link>
                </li>
                <li>
                    <Link to="/slavery-human-trafficking" className='color-white no-hover'>
                        Slavery & Human Trafficking
                    </Link>
                </li>
                <li>
                    <Link to="/Recruitment.html" className='color-white no-hover'>
                        Career
                    </Link>
                </li>
                <li>
                    <Link to="/Frequently-Asked-Questions-about-the-W.Hanson-Group-of-Timber-&-Builders-Merchants.html" className='color-white no-hover'>
                        FAQs
                    </Link>
                </li>
                <li>
                    <Link to="/Product-News.html" className='color-white no-hover'>
                        Product News
                    </Link>
                </li>
                <li>
                    <Link to="/Stock-Clearance-at-W.Hanson-(Iron-Bridge)-Ltd.html" className='color-white no-hover'>
                        Stock Clearance
                    </Link>
                </li>
            </ul>
        </aside>
        <aside className="widget widget_footer">
            <h4 className="widget-title">My Account</h4>
            <ul className="ps-list--link">
                <li>
                    <Link to="/users/login" className='color-white no-hover'>
                        Login
                    </Link>
                </li>
                <li>
                    <Link to="/profile" className='color-white no-hover'>
                        Order History
                    </Link>
                </li>

                {/* <li>
                    <Link to="/" className='color-white no-hover'>
                        Track Order
                    </Link>
                </li> */}
            </ul>
        </aside>
    </div>
);

export default FooterWidgets;
