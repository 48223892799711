import React from 'react'
import PageContainer from '../../components/layouts/PageContainer'
import BreadCrumb from '../../components/elements/BreadCrumb'
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import { Helmet } from 'react-helmet';

const Contact = () => {
    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "Contact Us",
        },
    ];
    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;

    return (
        <div>
            <Helmet>
                <title>W.Hanson Group - Contact W.Hanson for all your timber & building requiremetns</title>
                <meta name="keywords" Content="Timber Merchant, Builders Merchant, Timber Merchants, Builders merchants, timber supplies, building supplies, merchant, free delivery , mdf mouldings, skirting , architrave, machined, diy, Timber Merchant Southall, Builders merchant greenford, Timber Merchant Greenford, timber merchant ealing, builders merchant ealing, ealing timber, greenford timber, southall timber, Builders Merchant Southall, Timber Merchant West London, Builders Merchant West London, Timber Merchant Middlesex, Builders Merchant Middlesex, Timber Merchants Middlesex, Builders merchants Middlesex, Timber supplies London Middlesex, Building Supplies London Middlesex" />
                <meta name="description" Content="Contact W.Hanson Timber & Builders Merchants for all your timber & building requirements. We will deliver any stock materials out to you the following day free of charge. Our sales team are waiting to take your call."></meta>
            </Helmet>
            <PageContainer header={headers} footer={footer} title="Register">
                <div className="ps-page--my-account">
                    <BreadCrumb breacrumb={breadCrumb} />
                    <div className="ps-contact-info pb-0">
                        <div className="container">
                            <div className="ps-section__header">
                                <h3>Contact Us For Any Questions</h3>
                            </div>
                            <div className="ps-section__content">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ">
                                        <div className="ps-block--contact-info">
                                            <h4>Southall Branch</h4>
                                            <p>
                                                <p className='' style={{ color: "black" }}><i className='fa fa-map-marker pr-10'></i>
                                                    Southall, Uxbridge Road,entrance on Greenford Road,Southall,Middlesex,
                                                    UB1 3EQ

                                                </p>
                                                <a href="callto:020-8571-3161" className='mb-10 ' style={{ color: "black", fontSize: "17px", fontWeight: "500" }}>
                                                    <i className='fa fa-phone pr-10'></i> 020-8571-3161
                                                </a>
                                                <p>
                                                    <a href='mailto:020-8864-0822' style={{ color: "black", fontSize: "17px", fontWeight: "500" }}>
                                                        <i className='fa fa-envelope pr-10'></i>websales@w-hanson.co.uk
                                                    </a>
                                                </p>
                                                <div className='text-center'>
                                                    <a className='btn-location' href='https://maps.app.goo.gl/UXQDMB8uzA6GwinS7' target='_blank'>Go to Location<i class="fa fa-angle-right px-2"></i></a>
                                                </div>
                                            </p>

                                        </div>
                                        <div>
                                            <div>
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4966.274927864103!2d-0.35469720522274756!3d51.510694014564066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760d5e15c94bd7%3A0x9a1305f817da7192!2sSouthall%20UB1%203EQ%2C%20UK!5e0!3m2!1sen!2sin!4v1702451967000!5m2!1sen!2sin" height="400" allowfullscreen="" loading="lazy" className='w-100 border box-map-shadow' referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                        <div className="ps-block--contact-info">
                                            <h4>Harrow Branch</h4>
                                            <p>
                                                <p className='' style={{ color: "black" }}><i className='fa fa-map-marker pr-10'></i>
                                                    92-108 Greenford Road, Harrow, Middlesex, HA1 3QL
                                                </p>
                                                <a href="callto:020-8864-0822 " className='mb-10  w-100' style={{ color: "black", fontSize: "17px", fontWeight: "500" }}>
                                                    <i className='fa fa-phone pr-10'></i>
                                                    020-8864-0822
                                                </a>
                                                <p>
                                                    <a href='mailto:020-8864-0822' style={{ color: "black", fontSize: "17px", fontWeight: "500" }}>
                                                        <i className='fa fa-envelope pr-10'></i>websales@w-hanson.co.uk
                                                    </a>
                                                </p>
                                                <div>
                                                    <a href='https://maps.app.goo.gl/RAm7eyqJ2MZXPL8r9' target='_blank' className='btn-location'>Go to Location<i class="fa fa-angle-right px-2"></i></a>
                                                </div>
                                            </p>
                                        </div>
                                        <div className=''>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.516695338014!2d-0.3379942687492029!3d51.55876074469121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487613010954f09f%3A0xdc9bfcc8306acc88!2sGreenford%20Rd%2C%20Harrow%20HA1%203QL%2C%20UK!5e0!3m2!1sen!2sin!4v1702452159276!5m2!1sen!2sin" height="400" allowfullscreen="" className='w-100 border box-map-shadow' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ">
                                        <div className="ps-block--contact-info" style={{ maxWidth: "335px" }}>
                                            <h4>Northolt Branch</h4>
                                            <p className=''>
                                                <p className='' style={{ color: "black" }}><i className='fa fa-map-marker pr-10'></i>
                                                    Northolt,Unit 2 Northolt Trading Estate,Belvue Road,Northolt,
                                                    Middlesex,UB5 5QS,

                                                </p>
                                                <a href="callto:020-8845-2173" className='mb-10' style={{ color: "black", fontSize: "17px", fontWeight: "500" }}>
                                                    <i className='fa fa-phone pr-10'></i>
                                                    020-8845-2173
                                                </a>
                                                <p>
                                                    <a href='mailto:020-8864-0822' style={{ color: "black", fontSize: "17px", fontWeight: "500" }}>
                                                        <i className='fa fa-envelope pr-10'></i>websales@w-hanson.co.uk
                                                    </a>
                                                </p>
                                                <div>
                                                    <a href='https://maps.app.goo.gl/hoRGAqqQyn12Uxbk6' target='_blank' className='btn-location'>Go to Location<i class="fa fa-angle-right px-2"></i></a>
                                                </div>
                                            </p>
                                        </div>
                                        <div>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.253988606687!2d-0.36275932413114775!3d51.545241507939146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487612c4a530a16f%3A0x3d5bd66a2fb98624!2s2%20Belvue%20Rd%2C%20Northolt%20UB5%205QS%2C%20UK!5e0!3m2!1sen!2sin!4v1702452345559!5m2!1sen!2sin" height="400" className='w-100 border box-map-shadow' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ps-contact-form mt-5">
                    <div className="container">
                        <form className="ps-form--contact-us" action="/" method="get">
                            <h3>Get In Touch</h3>
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Name *"
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Email *"
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Phone *"
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Subject *"
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                    <div className="form-group">
                                        <textarea
                                            className="form-control"
                                            rows="5"
                                            placeholder="About Your Product"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group submit ">
                                <button className="ps-btn bg-blue color-white">Send message</button>
                            </div>
                        </form>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default Contact