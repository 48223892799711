import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

const WidgetShopCategories = () => {
  const params = useParams();
  const location = useLocation();
  const menuData = {
    menuPrimary: {
      menu_1: [
        {
          text: "Softwood",
          url: "Softwood",
          extraClass: "menu-item-has-children",
          subClass: "sub-menu",
          subMenu: [
            {
              text: "Cheshire Mouldings",
              url: "/category/cheshire-mouldings",
            },
            {
              text: "Firrings /Arris/ Featheredge /Fencing",
              url: "firrings",
            },
            {
              text: "Thermowood",
              url: "/category/thermowood",
            },
            {
              text: "Pellets",
              url: "/category/pellets",
            },
            {
              text: "C24 Softwood Treated",
              url: "/category/c24-softwood-treated",
            },
            {
              text: "Home Grown Eased Edge KD Treated",
              url: "/category/home_grown_eased_edge_kd_treated",
            },
            {
              text: "CLS",
              url: ""
            },
            {
              text: "PAR Contract Softwood",
              url: ""
            },
            {
              text: "PAR European Softwood",
              url: ""
            },
            {
              text: "PAR European Softwood Mouldings",
              url: ""
            },
            {
              text: "Ponted Pegs",
              url: ""
            },
            {
              text: "Sash Materials",
              url: ""
            },
            {
              text: "Sawn European Softwood",
              url: ""
            },
            {
              text: "Shrinkwrapped Door Linings",
              url: ""
            }
          ]
        },
        {
          text: "MDF Mouldings",
          url: "MDF_Mouldings",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          subMenu: [
            {
              text: "MDF Skirting & Archtrave",
              url: "MDF_Skirting_Archtrave",
            },
            {
              text: "MDF Window Boards",
              url: "MDF_Window_Boards",
            },
            {
              text: "MDF Door Stop & Misc",
              url: "MDF_Door_Stop_Misc",
            },
            {
              text: "MDF Linings",
              url: "MDF_Linings",
            },
          ],
        },
        {
          text: "Insulation",
          url: "Insulation",
          extraClass: "menu-item-has-children insulation",
          subClass: "sub-menu",
          mega: "true",
          subMenu: [
            {
              text: "Celotex",
              url: "Celotex",
            },
            {
              text: "Fibreglass",
              url: "Fibreglass",
            },
            {
              text: "Cavity Insulation",
              url: "Cavity_Insulation",
            },
            {
              text: "Polystyrene",
              url: "Polystyrene",
            },
          ],
        },
        {
          text: "Sheet Material",
          url: "/",
          current: "shop",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          subMenu: [
            {
              text: "Birch Plywood",
              url: "/",
            },
            {
              text: "Hardwood Throughout Plywood",
              url: "/",
            },
            {
              text: "Hardwood Throughout Plywood- FSC",
              url: "/",
            },
            {
              text: "Shuttering Plywood",
              url: "/",
            },
            {
              text: "Structural Plywood",
              url: "/",
            },
            {
              text: "Marine Plywood",
              url: "/",
            },
            {
              text: "OSB",
              url: "/",
            },
            {
              text: "MDF",
              url: "/",
            },
            {
              text: "Moisture Resistant MDF",
              url: "/",
            },
            {
              text: "Chipboard",
              url: "/",
            },
            {
              text: "Hardboard",
              url: "/",
            },
            {
              text: "Decorative MDF",
              url: "/",
            },
            {
              text: "Hardie Backer",
              url: "/",
            },
            {
              text: "Versafire",
              url: "/",
            },
            {
              text: "Iron On Edging",
              url: "/",
            },
            {
              text: "Melamine Faced MDF",
              url: "/",
            },
            {
              text: "Flexcell",
              url: "/",
            },
            {
              text: "Correx/ Protection",
              url: "/",
            },
          ],
        },
        {
          text: "Glues Sealants & Chemicals",
          url: "",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          subMenu: [
            {
              text: "CT1",
              url: "/",
            },
            {
              text: "Evo-Stik",
              url: "/",
            },
            {
              text: "Bond It",
              url: "/",
            },
            {
              text: "OB1",
              url: "/",
            },
          ],
        },
        {
          text: "Metalwork",
          url: "",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          subMenu: [
            {
              text: "Parkes Products Joist Hangers",
              url: "/",
            },
            {
              text: "Parkes Products Jiffy Hangers",
              url: "/",
            },
            {
              text: "Parkes Products Beading",
              url: "/",
            },
            {
              text: "Ties",
              url: "/",
            },
            {
              text: "Parkes Products Restraint Straps",
              url: "/",
            },
            {
              text: "Reinforcement Mesh",
              url: "/",
            },
            {
              text: "Metalwork Misc",
              url: "/",
            },
          ],
        },
        {
          text: "Aggregates & Heavyside",
          url: "",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          subMenu: [
            {
              text: "Bulk Bags",
              url: "/",
            },
            {
              text: "Mini Bags",
              url: "/",
            },
            {
              text: "Cement",
              url: "/",
            },
            {
              text: "Top Soil",
              url: "/",
            },
            {
              text: "Air Bricks",
              url: "/",
            },
            {
              text: "Aco Channel",
              url: "/",
            },
            {
              text: "Metal Stud & Track/ Suspended Ceiling",
              url: "/",
            },
          ],
        },
        {
          text: "Fixings",
          url: "/",
          current: "shop",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          subMenu: [
            {
              text: "TIMco Firmahold",
              url: "/",
            },
            {
              text: "TIMco Nails",
              url: "/",
            },
            {
              text: "TIMco Screws",
              url: "/",
            },
            {
              text: "TIMco Shield Protective Film",
              url: "/",
            },
            {
              text: "TIMco Cutting",
              url: "/",
            },
            {
              text: "TIMco Drilling and Driver Bits",
              url: "/",
            },

            {
              text: "TIMco Misc",
              url: "/",
            },
            {
              text: "Timco Bags",
              url: "/",
            },
            {
              text: "Timco Coach Bolts",
              url: "/",
            },
            {
              text: "Samac Screws",
              url: "/",
            },
            {
              text: "Drywall Screws",
              url: "/",
            },
            {
              text: "Studding",
              url: "/",
            },

            {
              text: "Loose Nails",
              url: "/",
            },
            {
              text: "Plasterboard/ Rawlplug Fixings",
              url: "/",
            },
            {
              text: "Masonry Nails",
              url: "/",
            },
            {
              text: "Misc Fixings & Ironmongery",
              url: "/",
            },
          ],
        },
        {
          text: "Drywall Products",
          url: "",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          subMenu: [
            {
              text: "Plasterboard",
              url: "/",
            },
            {
              text: "Plaster",
              url: "/",
            },
          ],
        },
        {
          text: "Concrete Products ",
          url: "",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          subMenu: [
            {
              text: "Pre-Stressed Lintels",
              url: "/",
            },
            {
              text: "Copings",
              url: "/",
            },
            {
              text: "Pier Caps",
              url: "/",
            },
            {
              text: "Padstones",
              url: "/",
            },
            {
              text: "Concrete Misc",
              url: "/",
            },
          ],
        },
      ],
    },
  };

  function slugify(str) {
    return str
      .replace(/ &/g, "")
      .replace(/\s+/g, "_") // Replace spaces with hyphens
      .replace(/\//g, "")
      .replace("-", "");
  }

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const datas = menuData.menuPrimary.menu_1.map((obj, i) => {
      if (slugify(obj.text) == params.slug) {
        setCategories(obj);
      } else {
        obj.subMenu?.map((items) => {
          if (slugify(items.text) == params.slug) {
            setCategories(obj);
          }
        });
      }
    });
  }, [location]);


  const [all_category, setAllCategory] = useState([])
  const [isLoading, setLoading] = useState(false)
  useEffect(() => {
    onAllCategory()
  }, [location])





  const onAllCategory = async (e) => {
    setLoading(true);
    try {
      await axios({
        method: "get",
        url: "https://whanson.ztpl.net/backend/api/v2/categories",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        const data = res.data?.filter((obj) => `/category/${obj.slug}` == location.pathname)
        const filteredData = res.data?.filter(item => {
          // Check if the name matches the targetName
          if (`/category/${item.slug}` == location.pathname) return true;

          // Check if any sub-object has a sub-name property matching the targetName
          if (item?.sucategories?.some(subItem => (`/category/${subItem.slug}` === location.pathname))) return true;

          return false;
        });

        setAllCategory(filteredData)
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  return (
    <aside className="widget widget_shop">
      <h4 className="widget-title">Categories</h4>
      <ul className="ps-list--categories colo-black">
        <li className="main-category">
          {all_category?.length != 0 ? (

            <a>{all_category[0]?.name} </a>
          ) : (
            <a>MDF Skirting & Archtrave </a>
          )}

        </li>
        {all_category?.length != 0 ? (
          <ul>
            {all_category[0]?.sucategories?.map((obj, i) => {
              return (
                <li className="sub-menu-side">
                  <Link to={`/category/${obj.slug}`} className={params.slug == obj.slug ? "side-active" : ""}>
                    <i className="icon-arrow-right pr-10"></i>
                    {obj.name}

                  </Link>
                  {obj.name == "MDF Skirting & Archtrave" ? (
                    <ul className="sub-menu-skirt">
                      <li>
                        <Link to='/category/Ogee' >Ogee</Link>
                      </li>
                      <li>
                        <Link to="/category/6mm_Pencil_Round">6mm Pencil Round</Link>
                      </li>
                      <li>
                        <Link to="/category/Torus">Torus</Link>
                      </li>
                      <li>
                        <Link to="/category/CPPLC1">CPPLC1</Link>
                      </li>
                      <li>
                        <Link to="/category/PSE_with_15x4_Groove_17mm_Flat">PSE with 15x4 Groove 17mm Flat</Link>
                      </li>
                      <li>
                        <Link to="/category/BK247">BK247</Link>
                      </li>
                      <li>
                        <Link to="/category/Square_Edge">Square Edge</Link>
                      </li>
                      <li>
                        <Link to="/category/3mm_Pencil_Round">3mm Pencil Round</Link>
                      </li>
                      <li>
                        <Link to="/category/Chamfered_&_Rounded">Chamfered & Rounded</Link>
                      </li>
                      <li>
                        <Link to="/category/SP600">SP600</Link>
                      </li>
                      <li>
                        <Link to="/category/2mm_Bevel">2mm Bevel</Link>
                      </li>
                      <li>
                        <Link to="/category/Twice_Grooved_10x4_groove_20mm_from_Top">Twice Grooved (10x4 groove) 20mm from Top</Link>
                      </li>
                    </ul>
                  ) : null}
                </li>
              );
            })}
          </ul>
        ) : all_category?.length == 0 ? (
          <ul>
            <li className="sub-menu-side">
              <Link to='/category/Ogee' className={params.slug == 'Ogee' ? "side-active" : ""} ><i className="icon-arrow-right pr-10"></i>Ogee</Link>
            </li>
            <li className="sub-menu-side">
              <Link to="/category/6mm_Pencil_Round"  className={params.slug == '6mm_Pencil_Round' ? "side-active" : ""}><i className="icon-arrow-right pr-10"></i>6mm Pencil Round</Link>
            </li>
            <li className="sub-menu-side">
              <Link to="/category/Torus" className={params.slug == 'Torus' ? "side-active" : ""}><i className="icon-arrow-right pr-10"></i>Torus</Link>
            </li>
            <li className="sub-menu-side">
              <Link to="/category/CPPLC1" className={params.slug == 'CPPLC1' ? "side-active" : ""}><i className="icon-arrow-right pr-10"></i>CPPLC1</Link>
            </li>
            <li className="sub-menu-side">
              <Link to="/category/PSE_with_15x4_Groove_17mm_Flat" className={params.slug == 'PSE_with_15x4_Groove_17mm_Flat' ? "side-active" : ""}><i className="icon-arrow-right pr-10"></i>PSE with 15x4 Groove 17mm Flat</Link>
            </li>
            <li className="sub-menu-side">
              <Link to="/category/BK247" className={params.slug == 'BK247' ? "side-active" : ""}><i className="icon-arrow-right pr-10"></i>BK247</Link>
            </li>
            <li className="sub-menu-side">
              <Link to="/category/Square_Edge" className={params.slug == 'Square_Edge' ? "side-active" : ""}><i className="icon-arrow-right pr-10"></i>Square Edge</Link>
            </li>
            <li className="sub-menu-side">
              <Link to="/category/3mm_Pencil_Round" className={params.slug == '3mm_Pencil_Round' ? "side-active" : ""}><i className="icon-arrow-right pr-10"></i>3mm Pencil Round</Link>
            </li>
            <li className="sub-menu-side">
              <Link to="/category/Chamfered_&_Rounded" className={params.slug == 'Chamfered_&_Rounded' ? "side-active" : ""}><i className="icon-arrow-right pr-10"></i>Chamfered & Rounded</Link>
            </li>
            <li className="sub-menu-side">
              <Link to="/category/SP600" className={params.slug == 'SP600' ? "side-active" : ""}><i className="icon-arrow-right pr-10"></i>SP600</Link>
            </li>
            <li className="sub-menu-side">
              <Link to="/category/2mm_Bevel" className={params.slug == '2mm_Bevel' ? "side-active" : ""}><i className="icon-arrow-right pr-10"></i>2mm Bevel</Link>
            </li>
            <li className="sub-menu-side">
              <Link to="/category/Twice_Grooved_10x4_groove_20mm_from_Top" className={params.slug == 'Twice_Grooved_10x4_groove_20mm_from_Top' ? "side-active" : ""}><i className="icon-arrow-right pr-10"></i>Twice Grooved (10x4 groove) 20mm from Top</Link>
            </li>
          </ul>
        ) : null}
      </ul>
    </aside>
  );
};

export default WidgetShopCategories;
