import React from 'react'
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import PageContainer from '../../components/layouts/PageContainer';
import BreadCrumb from '../../components/elements/BreadCrumb';
import TimberImg from "../../assets/static/img/timber-tent.jpg"
import Image1 from "../../assets/static/img/about3.png"
import Image2 from "../../assets/static/img/about4.png"
import Image3 from "../../assets/static/img/about2.png"

const Customised = () => {
    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "Bespoke Machine Services",
        },
    ];
    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;

    return (
        <div>
            <PageContainer header={headers} footer={footer} title="Register">
                <div className="ps-page--single">
                    <img src={Image3} height="300" style={{ width: "100%", objectFit: "cover", }} alt="" />
                    <div className="ps-page--my-account">
                        <BreadCrumb breacrumb={breadCrumb} />
                        <div className="ps-section--custom pb-0">
                            <div className="container">
                                <div className="ps-section__header">
                                    <h1 className="text-center" style={{ fontWeight: "550" }}>Bespoke Machine Services</h1>
                                </div>
                                <div className="ps-section__content term-section mt-40">
                                    <div className='pb-90 mb-5'>
                                        <div className="row justify-space-between">
                                            <div className='col-md-5'>
                                                <div className='position-relative'>
                                                    <img src={Image1} alt='No Img' className='bd-first-img' style={{ objectFit: "cover" }} />
                                                    <img src={Image2} alt='No Img' className='bd-second-img' width='280' />
                                                </div>
                                            </div>
                                            <div className='col-md-6' style={{ paddingLeft: "0px" }} >
                                                <div>
                                                    <p className='customised-text'>
                                                        <i className='icon-arrow-right pr-10'></i>We have a large selection of luxury Wooden Cottage tents that can be customised to meet the needs of our customers.  </p>
                                                    <p className='customised-text'>
                                                        <i className='icon-arrow-right pr-10'></i>These come in a variety of designs and styles and are ideal for use in campaigning.   </p>
                                                    <p className='customised-text'>
                                                        <i className='icon-arrow-right pr-10'></i>We have a large selection of luxury Wooden Cottage tents that can be customised to meet the needs of our customers.  </p>
                                                    <p className='customised-text'>
                                                        <i className='icon-arrow-right pr-10'></i>These come in a variety of designs and styles and are ideal for use in campaigning. We have a large selection of luxury Wooden Cottage tents that can be customised to meet the needs of our customers. </p>
                                                    <p className='customised-text'>
                                                        <i className='icon-arrow-right pr-10'></i>These come in a variety of designs and styles and are ideal for use in campaigning. </p>
                                                    <p className='customised-text'>
                                                        <i className='icon-arrow-right pr-10'></i>We have a large selection of luxury Wooden Cottage tents that can be customised to meet the needs of our customers. These come in a variety of designs and styles and are ideal for use in campaigning. </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='form-bg-blue'>
                                <div className='container'>
                                    <div className=' row justify-content-center pt-50 pb-50' >
                                        <div className='col-md-5'>
                                            <div style={{ backgroundImage: "url('https://images.unsplash.com/photo-1615840287214-7ff58936c4cf?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8Y29udGFjdCUyMHVzfGVufDB8fDB8fHww')", borderRadius: "5px" }}>
                                                <div className='overlay-service'>
                                                    <div className='contact-service-detail '>
                                                        <div>
                                                            <h1>Contact Details</h1>
                                                        </div>
                                                        <div className='mb-5'>
                                                            <div className='d-flex'>
                                                                <div className='contact-info-service text-center'><i className='fa fa-phone '></i></div>
                                                                <div>
                                                                    <h4>Call Us</h4>
                                                                    <p className='mb-0'> 020-8571-3161</p>
                                                                    <p className='mb-0'> 020-8864-0822</p>
                                                                    <p className='mb-0'> 020-8845-2173</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='d-flex'>
                                                                <div className='contact-info-service text-center'><i className='fa fa-envelope '></i></div>
                                                                <div>
                                                                    <h4>Write to Us</h4>
                                                                    <p className='mb-0'> websales@w-hanson.co.uk</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <form className="ps-form--contact-us col-md-7 career-form" action="/" method="get">
                                            <div className=' mb-5 pb-5'>
                                                <h1 className='' style={{ fontSize: '38px' }}>Get In Touch</h1>
                                                <h5 style={{ fontSize: '18px' }}>Fill the Form</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Enter Name *"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Enter Email *"
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Enter Phone Number *"
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Enter Subject *"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                    <div className="form-group">
                                                        <textarea
                                                            className="form-control"
                                                            rows="5"
                                                            placeholder="Can you clarify what you need? What are your requirements? "></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group submit ">
                                                <button className="ps-btn bg-blue color-white">Send message</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default Customised