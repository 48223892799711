import React, { useEffect } from 'react';
import SearchHeader from '../../../components/shared/headers/modules/SearchHeader';
import NavigationDefault from '../../../components/shared/navigation/NavigationDefault';
import HeaderActions from '../../../components/shared/headers/modules/HeaderActions';
import { stickyHeader } from '../../../utilities/common-helpers';
import MenuCategoriesDropdown from '../../../components/shared/menus/MenuCategoriesDropdown';

const HeaderDefault = () => {
    useEffect(() => {
        if (process.browser) {
            window.addEventListener('scroll', stickyHeader);
        }
    }, []);

    return (
        <header
            className="header header--1"
            data-sticky="true"
            id="headerSticky">
            <div className="header__top">
                <div className="ps-container">
                    <div className="header__left">
                        {/* <Logo /> */}
                        <MenuCategoriesDropdown />
                    </div>
                    <div className="header__center">
                        <SearchHeader />
                    </div>
                    <div className="header__right">
                        <HeaderActions />
                    </div>
                </div>
            </div>
            <NavigationDefault />
        </header>
    );
};

export default HeaderDefault;
