import { Rate } from 'antd'
import React from 'react'

const ProductReview = () => {
  return (
    <div>
      <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 ">
        <form className="ps-form--review" action="/" method="get">
          <h4>Submit Your Review</h4>
          <div className="form-group form-group__rating">
            <label>Your rating of this product</label>
            <Rate  defaultValue={1} />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows="2"
              placeholder="Write your review here"></textarea>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  ">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Your Name"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  ">
              <div className="form-group">
                <input
                  className="form-control"
                  type="email"
                  placeholder="Your Email"
                />
              </div>
            </div>
          </div>
          <div className="form-group submit">
            <button className="ps-btn bg-blue color-white">Submit Review</button>
          </div>
        </form>
      </div>{/*  */}
    </div>
  )
}

export default ProductReview