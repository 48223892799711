import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../../../../assets/static/img/w-logo.jpg";
import { Spin } from "antd";


const SearchHeader = () => {
  const inputEl = useRef(null);
  const [isSearch, setIsSearch] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);

  function handleClearKeyword() {
    setKeyword("");
    setIsSearch(false);
    setLoading(false);
  }

  function handleSubmit(e) {
    e.preventDefault();
    navigate(`/search?keyword=${serach}`);
  }
  function slugify(str) {
    return str
      .replace(/ &/g, "")
      .replace(/\s+/g, "_") // Replace spaces with hyphens
      .replace(/\//g, "")
      .replace("-", "");
  }

  const navigate = useNavigate();
  const [filterData, setFilterData] = useState([]);
  const [all_category, setAllCategory] = useState([])


  // Views
  let productItemsView,
    clearTextView,
    selectOptionView,
    loadMoreView;
  //   if (!loading) {
  if (filterData && filterData?.length > 0) {
    if (filterData.length > 5) {
      loadMoreView = (
        <div className="ps-panel__footer text-center">
          <Link href="/search">
            <a>See all results</a>
          </Link>
        </div>
      );
    }
  } else {
    productItemsView = <p>No product found.</p>;
  }
  if (keyword !== "") {
    clearTextView = (
      <span className="ps-form__action" onClick={handleClearKeyword}>
        <i className="icon icon-cross2"></i>
      </span>
    );
  }

  selectOptionView = all_category.map((option , i) => (
    <option value={option.slug} key={i}>
      {option.name}
    </option>
  ));

  const [serach, setSerach] = useState("");
  const [isLoading, setFilterLoading] = useState(false);
  useEffect(() => {
    if (data?.length == 0) {
      onSerachFilter();
    }
  }, [serach]);

  const [data, setData] = useState([]);

  const onSerachFilter = async (e) => {
    setFilterLoading(true);
    try {
      await axios({
        method: "get",
        url: "https://whanson.ztpl.net/backend/api/v2/allproducts",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        setData(res.data.data);
        // setFilterData(
        //   res.data.data.filter((item) =>
        //     item.name.toLowerCase().includes(serach.toLowerCase())
        //   )
        // );
        setFilterLoading(false);
      });
    } catch (error) {
      console.log(error);
      setFilterLoading(false);
    }
  };


  useEffect(() => {
    if (all_category?.length == 0) {
      onAllCategory()
    }
  }, [])


  const onAllCategory = async (e) => {
    setFilterLoading(true);
    try {
      await axios({
        method: "get",
        url: "https://whanson.ztpl.net/backend/api/v2/categories",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        setAllCategory(res.data)
        setFilterLoading(false);
      });
    } catch (error) {
      console.log(error);
      setFilterLoading(false);
    }
  };


  return (
    <form
      className="ps-form--quick-search"
      method="get"
      action="/"
      onSubmit={handleSubmit}
    >
      <div className="ps-form__categories">
        <select
          className="form-control"
          style={{ textIndent: "0px" }}
          onChange={(e) => {
            if (e.target.value != "All Products") {
              navigate(`/category/${slugify(e.target.value)}`);
            }
          }}
        >
          <option>All Products</option>
          {selectOptionView}
        </select>
      </div>
      <div className="ps-form__input">
        <input
          ref={inputEl}
          className="form-control"
          type="text"
          placeholder="I'm shopping for..."
          // value={serach}
          onChange={(e) => {
            setSerach(e.target.value);
            setFilterData(
              data.filter((item) =>
                item.name.toLowerCase().includes(e.target.value.toLowerCase())
              )
            );
          }}
        />
        {serach?.length != 0 ? (
          <span
            className="ps-form__action"
            onClick={() => setSerach("")}
            style={{ cursor: "pointer" }}
          >
            <i className="icon icon-cross2"></i>
          </span>
        ) : null}
      </div>
      <button
        className="bg-blue "
        style={{ color: "white" }}
        onClick={handleSubmit}
      >
        Search
      </button>
      <div
        className={`ps-panel--search-result${serach?.length != 0 ? " active " : " "
          }`}
      >
        {isLoading && (
          <span
            className="ps-form__action text-center"
            style={{ display: "inline-block", width: "100%" }}
          >
            <Spin size="small" />
          </span>
        )}
        <div className="ps-panel__content ">
          {filterData.map((product) => {
            return (
              <div
                className="ps-product ps-product--wide ps-product--search-result"
                key={product.id}
                style={{ marginBottom: "10px" }}
              >
                <div className="ps-product__thumbnail">
                  <Link to={`/product/${product.slug}`}>
                    <a>
                      <img
                        src={
                          product.thumbnail_image != null
                            ? product.thumbnail_image
                            : logo
                        }
                        width="50"
                        alt="No Img"
                      />
                    </a>
                  </Link>
                </div>
                <div className="ps-product__content">
                  <Link to={`/product/${product.slug}`} style={{ color: "black" }}>{product.name}</Link>
                  <div className="ps-product__rating"></div>
                  £{parseFloat(product.price).toFixed(2)}
                </div>
              </div>
            );
          })}
          {filterData?.length == 0 ? <p>No product found.</p> : null}
        </div>
        {loadMoreView}
      </div>
    </form>
  );
};

export default SearchHeader;
