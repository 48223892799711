import React from 'react'
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import { Helmet } from 'react-helmet';
import PageContainer from '../../components/layouts/PageContainer';
import BreadCrumb from '../../components/elements/BreadCrumb';
import Img1 from "../../assets/static/img/grp-lisdt2.png"
import DownLoadPdf from "../../assets/static/img/KOTA Brochure.pdf"

const ProductNews = () => {
    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "Product News",
        },
    ];
    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;

    return (
        <div>
            <Helmet>
                <title>W.Hanson Group - Product News</title>
                <meta NAME="Keywords" Content="Product News, New Product, Kota, Second Hand Yellow Stock, Yellow London Stock Brick, Timber Merchant, Builders Merchant, Timber Merchants, Builders merchants, Timber supplies London, Building Supplies London, Timber Merchant Southall, Builders merchant greenford, Timber Merchant Greenford, timber merchant ealing, builders merchant ealing, ealing timber, greenford timber, southall timber, Builders Merchant Southall, Timber Merchant West London, Builders Merchant West London, Special Moulding London, Machined Timber London, Machined Timber Southall, softwood London, Hardwood London, Softwood Southall, Hardwood Southall , Softwood London, Hardwood London, Skirting, Architrave, MDF, Plywood, Veneered MDF, , timber supplies, building supplies, merchant, free delivery , mdf mouldings, skirting , architrave, machined, diy, west london, Timber Merchant Middlesex, Builders Merchant Middlesex, Timber Merchants Middlesex, Builders merchants Middlesex, Timber supplies London Middlesex, Building Supplies London Middlesex" />
                <meta NAME="Description" Content="Check out our new products currently available in branch. We are very excited sbout the new Kota paint no more MDF mouldings. We believe that this going to become the norm in housebuilding in the near future. Also now in stock is a superb copy of a second hand yellow London stock brick. With second hand yellow stock bricks exztremely hard to come by and extremely expensive, we believe that we have managed to source a superb option at a massive saving." />
            </Helmet>
            <PageContainer header={headers} footer={footer} title="Register">
                <div className="ps-page--single">
                    <BreadCrumb breacrumb={breadCrumb} />
                    <div className="container">
                        <div>
                            <div className='row align-items-center mt-5 mb-5'>
                                <div className='col-md-4'>
                                    <div className=' position-relative'>
                                        <div className='bd-img-sets'>
                                            <img src={Img1} alt='No Img' />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-8  '>
                                    <h1 className='mb-5'>KOTA PAINT NO MORE MOULDINGS</h1>
                                    <h3 className='mb-4'><strong>W.Hanson </strong> are very proud to be associated with <strong>KOTA</strong> as a distributor.</h3>
                                    <p className=''><i className='icon-arrow-right pr-10'></i>
                                        Please check out the link below for details of this exciting new product.</p>
                                    <div className='mt-3 mb-3'>
                                        <a class="btn-blue mr-20 " href={DownLoadPdf} download target='_blank'>KOTA Brochure.pdf</a>
                                    </div>
                                    <p>More details to follow shortly. </p>
                                    <h2 className='mt-3 mb-3'>PRO GRP now in stock at Harrow!</h2>

                                    <h1 className='mb-4 mt-4'>We now have extensive stock of GRP fibreglass roofing. Very competitively priced. We are also offering certified installation training in partnership our supplier.</h1>
                                    <h3>Book now & start to save money & time on a much better job! </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>

        </div>
    )
}

export default ProductNews