import React, { useEffect } from "react";
import PageContainer from "../../components/layouts/PageContainer";
import BreadCrumb from "../../components/elements/BreadCrumb";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import PEFC from "../../assets/static/img/Ironbridge PEFC_August 2022.pdf";
import FSC from "../../assets/static/img/Ironbridge FSC_August 2022.pdf";
import picture1 from "../../assets/static/img/Picture1.png";
import picture2 from "../../assets/static/img/Picture2.png";
import picture3 from "../../assets/static/img/Picture3.png";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const ChainofCustody = () => {

  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (
  //     !localStorage.getItem("auth_user")
  //   ) {
  //     navigate("/login");
  //   }
  // }, []);

  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Chain of Custody",
    },
  ];
  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;
  return (
    <div>
      <Helmet>
        <title>W.Hanson Group - The W.Hanson Groups Chain of Custody & FSC & PEFC Certificates</title>
        <meta NAME="Keywords" Content="Environment, PEFC, FSC, Chain of Custody, Renewable sources, Sustainability, Sustainable source, Timber Merchant, Builders Merchant, Timber Merchants, Builders merchants, timber supplies, building supplies, merchant, free delivery , mdf mouldings, free delivery , mdf mouldings, skirting , architrave, machined, diy, west london, Timber Merchant Southall, Builders merchant greenford, Timber Merchant Greenford, timber merchant ealing, builders merchant ealing, ealing timber, greenford timber, southall timber, Builders Merchant Southall, Timber Merchant West London, Builders Merchant West London" />
        <meta NAME="Description" Content="W.Hanson Timber & Builders Merchants have a strong commitment to help protect the environment. We have full chain of custody for both FSC & PEFC materials, which ensures that all materials that we sell have the highest level of integrity." />

      </Helmet>
      <PageContainer header={headers} footer={footer} title="Register">
        <div className="ps-page--my-account">
          <BreadCrumb breacrumb={breadCrumb} />
          <div className="ps-section--custom">
            <div className="container">
              <div className="ps-section__header">
                <div className="ps-section--vendor term-section bd-privacy">
                  <div className="ps-section__header mb-0 pb-0 mt-20">
                    <h3>Environmental Accreditation / Chain of Custody</h3>
                  </div>
                  <p className="mt-30">
                    <strong>W.Hanson (Iron Bridge) Ltd </strong> has accreditation, through  <strong>Forest Management Certification  </strong>, for our own  <strong>'Chain of Custody'</strong> for both FSC® (W.Hanson (IB) Ltd - FSC-C007970 PEFC/16-37-1746 products. This allows to obtain and sell on to our customers, materials that have the highest level of integrity.
                  </p>
                  <p>
                    Austrian, German, Finnish and Scandanavian forests lead the way in terms of environmental management, from where a high proportion of <strong>PEFC/16-37-1746 (Programme for the Endorsement of Forest Certification schemes) </strong> certified timber products are manufactured. The W.Hanson Group purchase softwood mouldings & PSE, softwood linings, CLS and Spruce from these countries, and with our <strong> 'Chain of Custody' </strong>can verify their <strong>PEFC/16-37-1746  </strong>certification from point of purchase through to point of sale.
                  </p>
                  <div className="mt-40 mb-5">
                    <div className="d-flex justify-space-between">
                      <h2 className="col-7 pl-0">
                        W Hanson Ironbridge Ltd - FSC-C007970
                      </h2>
                      <a className="btn-blue" href={FSC} download>
                        Ironbridge FSC 2022.pdf
                      </a>
                    </div>
                    <div className="d-flex justify-space-between mt-3">
                      <h2 className="col-7 pl-0">
                        W Hanson Ironbridge Ltd  - PEFC/16-37-1746
                      </h2>
                      <a className="btn-blue" href={PEFC} download>
                        Ironbridge PEFC 2022.pdf
                      </a>
                    </div>
                  </div>
                  <p> Likewise, <strong>FSC® (Forest Stewardship Council®)  </strong> certification covers products such as MDF, MDF mouldings, OSB and T&G Chipboard flooring. Again our <strong>'Chain of Custody' </strong> can verify their <strong>FSC® </strong>certification from point of purchase through to point of sale.</p>

                  <div className="ps-section__content mt-70">
                    <div className="ps-block--vendor-milestone">
                      <div className="ps-block__left">
                        <h4> Environment </h4>
                        <p>
                          The W.Hanson Group Ltd is committed to minimising the
                          impact of its activities on the environment.
                        </p>
                        <p>
                          The key points of our strategy to achieve this are:
                        </p>
                        <ul>
                          <li>
                            Minimise waste by evaluating operations and ensuring
                            they are as efficient as possible.
                          </li>
                          <li>
                            Actively promote recycling both internally and
                            amongst its customers and suppliers.
                          </li>
                          <li>
                            Source and promote a product range to minimise the
                            environmental impact of both production and
                            distribution.
                          </li>
                          <li>
                            Meet or exceed all the environmental legislation
                            that relates to the Company.
                          </li>
                          <li>
                            Use an accredited program to offset the greenhouse
                            gas emissions generated by our activities.
                          </li>
                        </ul>
                      </div>
                      <div className="ps-block__right">
                        <img src={picture1} height="290" alt="No Img" />
                      </div>
                      <div className="ps-block__number">
                        <span>1</span>
                      </div>
                    </div>
                    <div className="ps-block--vendor-milestone reverse">
                      <div className="ps-block__left">
                        <h4>Environmental Statement</h4>
                        <p>
                          W.Hanson Group recognises the need for sustainable
                          development and continually aims to improve the
                          environmental effect of its activities, to acheive
                          this we will:
                        </p>
                        <p>Establish sound environmental management by:-</p>
                        <ul>
                          <li>
                            Meeting or improving upon relevant legislative,
                            regulatory and environmental codes of practice.
                          </li>
                          <li>
                            Developing objectives that target environmental
                            improvements and monitor performance by regular
                            review.
                          </li>
                          <li>
                            Considering any environmental issues in the
                            decision-making process.
                          </li>
                          <li>
                            Developing a relationship with suppliers and
                            contractors so that we all recognise our
                            environmental responsibilities.
                          </li>
                          <li>
                            Educating staff so that they carry out their
                            activities in an environmentally responsible manner.
                          </li>
                        </ul>
                      </div>
                      <div className="ps-block__right">
                        <img
                          src={picture2}
                          height="290"
                          width="350"
                          alt="No Img"
                        />
                      </div>
                      <div className="ps-block__number">
                        <span>2</span>
                      </div>
                    </div>
                    <div className="ps-block--vendor-milestone">
                      <div className="ps-block__left">
                        <p>Provide for the effective use of resources by:-</p>
                        <ul>
                          <li>
                            Promoting waste minimisation by recycling or finding other uses for by-products whenever environmentally viable.
                          </li>
                          <li>
                            Promoting the efficient use of resources, energy & fuel throughout the organization's operations.
                          </li>
                          <li>Considering any environmental issues in the decision-making process.</li>
                          <li>Developing a relationship with suppliers and contractors so that we all recognise our environmental responsibilities.</li>
                          <li>Educating staff so that they carry out their activities in an environmentally responsible manner.</li>
                        </ul>
                        <p>Co-operate with:-</p>
                        <ul>
                          <li>The communities in which we operate.</li>
                          <li>The Government, regulatory bodies and other interested parties with the shared vision of being a good and trusted neighbour.</li>
                        </ul>
                      </div>
                      <div className="ps-block__right">
                        <img src={picture3} height="290" alt="No Img" />
                      </div>
                      <div className="ps-block__number">
                        <span>3</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default ChainofCustody;
