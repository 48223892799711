import React, { useEffect, useState } from "react";
import PageContainer from "../../components/layouts/PageContainer";
import BreadCrumb from "../../components/elements/BreadCrumb";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Radio } from "antd";

const Shipping = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem("auth_id") &&
      !localStorage.getItem("auth_name")
    ) {
      navigate("/users/login");
    }
  }, []);

  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Shipping Info",
    },
  ];
  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;

  const [payment, setPayment] = useState(false);

  const [method, setMethod] = useState(1);

  function handleChangeMethod(e) {
    setMethod(e.target.value); //e.target.value
  }

  return (
    <div>
      <PageContainer header={headers} footer={footer} title="Register">
        <div className="ps-page--my-account">
          <BreadCrumb breacrumb={breadCrumb} />
          <div className="ps-checkout ps-section--shopping pt-60">
            <div className="container">
              <div
                className="ps-section__header "
                style={{ paddingBottom: "50px" }}
              >
                <h1 style={{ fontSize: "30px", textAlign: "left" }}>
                  Shipping Information
                </h1>
              </div>
              <div className="ps-section__content">
                <div className="row">
                  <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                    <Form className="ps-form__billing-info">
                      <h3 className="ps-form__heading">Contact information</h3>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="First Name"
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="Email or phone number"
                            />
                          </div>
                        </div>
                      </div>

                      <h3 className="ps-form__heading mt-20">
                        Shipping address
                      </h3>

                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <Input
                              className="form-control"
                              type="city"
                              placeholder="Country"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <Input
                              className="form-control"
                              type="postalCode"
                              placeholder="State"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <Input
                              className="form-control"
                              type="city"
                              placeholder="City"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <Input
                              className="form-control"
                              type="postalCode"
                              placeholder="Postal Code"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Address"
                        />
                      </div>

                      <div className="ps-block__footer d-flex justify-space-between  mt-30">
                        <Link href="/account/checkout">
                          <a>
                            <i className="icon-arrow-left mr-2"></i>
                            Return to Information
                          </a>
                        </Link>
                        {/* <Link to="/v1/checkout/payment"> */}
                          <a className="ps-btn bg-blue color-white" onClick={() => setPayment(true)}>
                            Continue to payment
                          </a>
                        {/* </Link> */}
                      </div>
                    </Form>
                    {payment ? (
                      <>
                        <h4>Payment Methods</h4>
                        <div className="ps-block--payment-method">
                          <div className="ps-block__header">
                            <Radio.Group
                              onChange={(e) => handleChangeMethod(e)}
                              value={method}
                            >
                              <Radio>Cash on Delivery</Radio>
                              <Radio value={2}>Paypal</Radio>
                              <Radio value={3}>Stripe</Radio>
                            </Radio.Group>
                          </div>
                          <div className="ps-block__content">
                            {method === 2 ? (
                              <div className="ps-block__tab">
                                <a
                                  className="ps-btn bg-blue color-white"
                                  href="https://www.paypal.com/"
                                  target="_blank"
                                >
                                  Process with Paypal
                                </a>
                              </div>
                            ) : method === 3 ? (
                              <div className="ps-block__tab">
                                <a
                                  className="ps-btn bg-blue color-white"
                                  href="https://dashboard.stripe.com/login"
                                  target="_blank"
                                >
                                  Process with Stripe
                                </a>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12  ps-block--checkout-order">
                    <div className="ps-form__orders">
                      <h3>Your order</h3>
                      <div className="ps-block--checkout-order">
                        <div className="ps-block__content   ">
                          <figure className="mb-20">
                            <figcaption>
                              <strong>Product</strong>
                              <strong>total</strong>
                            </figcaption>
                          </figure>
                          <figure className="ps-block__items">
                            <div className="d-flex justify-space-between">
                              <a className="col-9 ">
                                <strong>
                                  OB1 Multi Surface Sealant & Adhesive
                                  <span>x 1</span>
                                </strong>
                              </a>
                              <a className="col-2 ">
                                <small>£111.12</small>
                              </a>
                            </div>
                          </figure>
                          <figure>
                            <figcaption>
                              <strong>Subtotal</strong>
                              <small>
                                <strong>£111.12</strong>
                              </small>
                            </figcaption>
                          </figure>
                          <figure>
                            <figcaption>
                              <strong>Shipping Fee</strong>
                              <small>
                                <strong>£20.00</strong>
                              </small>
                            </figcaption>
                          </figure>
                          <figure className="ps-block__total mb-0 pb-0 border-bottom-none">
                            <h3>
                              Total
                              <strong>£131.12</strong>
                            </h3>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default Shipping;
