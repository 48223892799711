import React from 'react';
import { Link, useParams } from 'react-router-dom';


const MenuDropdown = ({ source }) => {

    const params = useParams();

    function slugify(str) {
        return str
            .replace(/ &/g, '')
            .replace(' -', '')
            .replace(/\s+/g, '_') // Replace spaces with hyphens
            .replace(/\//g, '')
    }

    return (
        <li className={"menu-item-has-children dropdown " + source.text}>
            {
                <Link to={source.url == `/category/${slugify(source.text)}` ? '' : `/category/${slugify(source.text)}`}
                className={source.url == "mdf-skirting-&-archtrave" ? 'mdf-skirting' : "" } >
                    <a className={slugify(source.text) == params.slug ? 'active-head' : ""} >{source.text}</a>
                    {source.url == "mdf-skirting-&-archtrave" ? (
                        <ul className='sub-menu-menu'>
                            <li>
                                <Link to='/category/Ogee' >Ogee</Link>
                            </li>
                            <li>
                                <Link to="/category/6mm_Pencil_Round">6mm Pencil Round</Link>
                            </li>
                            <li>
                                <Link to="/category/Torus">Torus</Link>
                            </li>
                            <li>
                                <Link to="/category/CPPLC1">CPPLC1</Link>
                            </li>
                            <li>
                                <Link to="/category/PSE_with_15x4_Groove_17mm_Flat">PSE with 15x4 Groove 17mm Flat</Link>
                            </li>
                            <li>
                                <Link to="/category/BK247">BK247</Link>
                            </li>
                            <li>
                                <Link to="/category/Square_Edge">Square Edge</Link>
                            </li>
                            <li>
                                <Link to="/category/3mm_Pencil_Round">3mm Pencil Round</Link>
                            </li>
                            <li>
                                <Link to="/category/Chamfered_&_Rounded">Chamfered & Rounded</Link>
                            </li>
                            <li>
                                <Link to="/category/SP600">SP600</Link>
                            </li>
                            <li>
                                <Link to="/category/2mm_Bevel">2mm Bevel</Link>
                            </li>
                            <li>
                                <Link to="/category/Twice_Grooved_10x4_groove_20mm_from_Top">Twice Grooved (10x4 groove) 20mm from Top</Link>
                            </li>
                        </ul>
                    ) : null}
                </Link>
            }
            {source.subMenu && (
                <ul className={source.subClass}>
                    {source.subMenu.map((subMenuItem, index) => (
                        <MenuDropdown source={subMenuItem} key={index}  />
                    ))}
                </ul>
            )}
        </li>
    );
};

export default MenuDropdown;
