import React, { useEffect, useRef, useState } from "react";
import BreadCrumb from "../../components/elements/BreadCrumb";
import WidgetShopCategories from "../../components/shared/widgets/WidgetShopCategories";
import PageContainer from "../../components/layouts/PageContainer";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import { Slider } from "antd";
import axios from "axios";
import Loader from "../Loader";
import NoImg from "../../assets/static/img/w-logo.jpg"
import ReactPaginate from 'react-paginate';
import config from "../../config";
import LightBoxicon from "./LightBox";
import { Helmet } from "react-helmet";


const Categories = () => {
  const params = useParams();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (
  //     !localStorage.getItem("auth_user")
  //   ) {
  //     navigate("/login");
  //   }
  // }, []);

  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Categories",
      url: "/",
    },
    {
      text: params.slug ? params.slug : "",
    },
  ];

  //Views

  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;


  const location = useLocation();

  const [isCalled, setCalled] = useState(false)

  const [itemOffset, setItemOffset] = useState(0);


  useEffect(() => {
    if (location.pathname) {
      setItemOffset(0)
    }
    fetchDryWallProduc()
  }, [location.pathname, isCalled])

  const [isLoading, setLoading] = useState(false)
  const [product_list, setProductList] = useState([])
  const [allData, setAllData] = useState([])

  const [count, setCount] = useState('')

  const fetchDryWallProduc = async () => {
    // setProductList([])
    setLoading(true)
    const formData = new FormData();
    formData.append('page', (itemOffset / 30) + 1)
    formData.append('pageSize', 30)
    try {
      await axios({
        method: "post",
        data: formData,
        url: `${config.apiUrl}/products/category/${params.slug}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        function customSort(a, b) {
          if (a.thumbnail_image === null || a.thumbnail_image === undefined) {
            return 1;
          }
          if (b.thumbnail_image === null || b.thumbnail_image === undefined) {
            return -1;
          }
          return 0;
        }
        const sortedArray = res.data.data.sort(customSort);
        setProductList(sortedArray)
        setAllData(sortedArray)
        setLoading(false)
        setCount(res.data.meta.total)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }



  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);


  const [itemsPerPage, setitemsPerPage] = useState(30)
  useEffect(() => {

    // setCurrentItems([])
    const endOffset = itemOffset + itemsPerPage;
    // setCurrentItems(product_list.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(count / itemsPerPage))
  }, [product_list, isCalled])



  const targetDivRef = useRef(null);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const targetDiv = targetDivRef.current;
    targetDiv.scrollIntoView({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setCalled(!isCalled)
    const newOffset = (event.selected * itemsPerPage) % count;
    setItemOffset(newOffset);

  };

  function slugify(slug) {
    return slug
      .replace(/_/g, " ")
  }



  useEffect(() => {
    let maxPrice;
    let minPrice;
    if (allData?.length >= 1) {
      minPrice = parseFloat(allData[0]?.price);
      maxPrice = parseFloat(allData[0]?.price);
    }

    // Iterate through the array to find the minimum and maximum prices
    allData.forEach((item) => {
      const price = parseFloat(item.price);
      if (price < minPrice) {
        minPrice = price;
      }
      if (price > maxPrice) {
        maxPrice = price;
      }
    });
    setMin(minPrice)
    setMax(maxPrice)

    setSliderValue([minPrice, maxPrice])

  }, [allData])

  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const [slider_value, setSliderValue] = useState([])

  const onChangeValue = (e) => {
    setSliderValue(e)
    const filteredProducts = allData.filter((product) => {
      const price = product.price;
      return price >= e[0] && price <= e[1];
    });
    setProductList(filteredProducts)
  }

  const onChangeBrand = (e) => {
    if (e.target.value != "" && e.target.value != "all") {
      const filterData = allData.filter((obj) => (obj.brand == e.target.value))
      setProductList(filterData)
    } else {
      setProductList(allData)
    }
  }

  const onSortBy = (e) => {
    if (e.target.value == "") {
      setCalled(!isCalled)
      setProductList(product_list)
    } else if (e.target.value == "Newest") {
      setCalled(!isCalled)
      setProductList(product_list.sort((a, b) => b.id - a.id))
    } else if (e.target.value == "Oldest") {
      setCalled(!isCalled)
      setProductList(product_list.sort((a, b) => a.id - b.id))
    } else if (e.target.value == "Low-High") {
      setCalled(!isCalled)
      setProductList(product_list.sort((a, b) => a.price - b.price))
    } else if (e.target.value == "High-Low") {
      setCalled(!isCalled)
      setProductList(product_list.sort((a, b) => b.price - a.price))
    }
  }

  const [itemsInStorage, setItemsInStorage] = useState(
    JSON.parse(localStorage.getItem("items")) || []
  );







  return (
    <>
      <Helmet>
        <title >W.Hanson Group - {params.slug?.replace("_", " ")}</title>

      </Helmet>
      <PageContainer header={headers} footer={footer} boxed={true}>
        {isLoading && (<Loader />)}
        <div className="ps-page--shop" ref={targetDivRef}>
          <BreadCrumb breacrumb={breadCrumb} />
          <div className="container">
            <div className="ps-layout--shop ps-shop--category">
              <div className="ps-layout__left">
                <WidgetShopCategories />

                <aside className="widget widget_shop">
                  <figure>
                    <h4 className="widget-title">By Price</h4>
                    <Slider
                      range
                      value={slider_value}
                      onChange={onChangeValue}
                      max={max}
                      min={min}
                    />
                    <p>
                      Min-Price: £ {parseFloat(min)?.toFixed(2)} <br />  Max-Price : £ {parseFloat(max)?.toFixed(2)}
                    </p>
                  </figure>
                </aside>
              </div>
              <div className="ps-layout__right">
                <h3 className="ps-shop__heading" style={{ textTransform: "capitalize" }}>{slugify(params?.slug)} <span className="text-danger mx-2" style={{ fontSize: "12.5px" }}>{params.slug == "MDF_Linings_sets" ? "*Please allow extended lead time on this product of 2-4 weeks" : ''}</span></h3>
                <div className="ps-shopping pb-0">
                  <div className="ps-shopping__header">
                    <p>
                      <strong className="mr-2">{count}</strong>Products found
                    </p>
                    <div className="ps-shopping__actions">
                      <select
                        className="ps-select form-control"
                        data-placeholder="Sort Items"
                        onChange={onChangeBrand}
                      >
                        <option value=''>Sort by Type</option>
                        <option value='all'>All Types</option>
                        <option value='Standard'>Standard</option>
                        <option value='Sheet'>Sheet</option>
                        <option value='Linear Component'>Linear Component</option>
                        <option value='Linear'>Linear</option>
                      </select>
                      <select
                        className="ps-select form-control ml-10"
                        data-placeholder="Sort Items"
                        onChange={onSortBy}
                      >
                        <option value=''>Sort By</option>
                        <option value="Newest">Newest</option>
                        <option value='Oldest' >Oldest</option>
                        <option value="Low-High">Price: Low to High</option>
                        <option value="High-Low">Price: High to Low</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    {product_list.map((items, i) => {

                      const stars = [];
                      for (let i = 1; i <= 5; i++) {
                        const className = i <= items.rating ? 'fa fa-star' : 'fa fa-star-o';
                        stars.push(
                          <i className={className} key={i}></i>
                        );
                      }

                      let maxPrice = 0;
                      let minPrice = 0;
                      if (items?.stock?.length >= 1) {
                        minPrice = parseFloat(items?.stock[0]?.price).toFixed(2);
                        maxPrice = parseFloat(items?.stock[0]?.price).toFixed(2);
                      }

                      let Price
                      if (items?.stock?.length == 1) {
                        Price = "£" + parseFloat(items?.stock[0]?.price).toFixed(2);
                      } else {
                        items?.stock?.forEach((item) => {
                          const price = parseFloat(item.price).toFixed(2);
                          if (price < minPrice) {
                            minPrice = price;
                          }
                          if (price > maxPrice) {
                            maxPrice = price;
                          }
                        });
                        Price = "£" + minPrice + " - " + "£" + maxPrice;
                      }



                      let Badge;
                      if (items.discount != null) {
                        Badge = <div class="ps-product__badge" style={{ zIndex: "99" }} >{items?.discount} %</div>
                      } else if (items?.spot_deal != null) {
                        Badge = <div class="ps-product__badge" style={{ zIndex: "99" }} >{items?.spot_deal} %</div>
                      } else if (items.discount == null && items.spot_deal == null) {
                        Badge = <></>
                      }

                      return (
                        <div key={i} className="col-md-4 pl-0 pr-0 big-resp" >
                          <div className={items.name?.length > 130 ? "ps-product ps-product--inner all-product-card h-450" : "ps-product ps-product--inner all-product-card h-340"}>
                            <div className="ps-product__thumbnail text-center">
                              {Badge}
                              {items.thumbnail_image != null ? (
                                <Link
                                  to={`/product/${items.slug}`}
                                >
                                  <img src={items.thumbnail_image} alt="No img" height='171' style={{ objectFit: "cover" }} />
                                </Link>
                              ) : (
                                <div style={{ height: "171px" }}>
                                  <Link
                                    to={`/product/${items.slug}`}
                                  >
                                    <img src={NoImg} alt="No img" height='200' className="mt-15" />
                                  </Link>
                                </div>
                              )}

                              <LightBoxicon productId={items.id} />

                            </div>
                            <div className="ps-product__container">
                              <Link
                                to={`/product/${items.slug}`}
                                className="ps-product__vendor"
                                href="/shop"
                              >
                                {items?.name}
                              </Link>
                              <div className="ps-product__rating text-center ">
                                <span className="ps-rating">
                                  {stars}
                                </span>
                              </div>
                              <div className="ps-product__content">
                                <div className="text-center price-fixed">
                                  <p className="ps-product__price sale" style={{ fontSize: "16px" }}>
                                    <span className="text-black" style={{ color: "black" }}> Price Range :</span>  <br /> {Price}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-30">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="Next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="Previous"
                      renderOnZeroPageCount={null}
                      breakClassName={'page-item'}
                      breakLinkClassName={'ant-pagination-item-link'}
                      containerClassName={'pagination'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'ant-pagination-item-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'ant-pagination-item-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'ant-pagination-item-link'}
                      activeClassName={'active'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </>
  );
};
export default Categories;
