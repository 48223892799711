import { CardElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import React, { useState } from 'react'
import Loader from '../Loader';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = ({ MainTotal }) => {


    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const [success, setSuccess] = useState(false);
    const [isLoading, setLoading] = useState(false)

    const handlePayment = async (e) => {
        e.preventDefault();
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        })


        if (!error) {
            setLoading(true)
            try {
                const { id } = paymentMethod
                const response = await axios.post('https://whanson.ztpl.net/backend/api/v2/createpayment', {
                    amount: parseFloat(MainTotal).toFixed(0),
                    method: paymentMethod?.id
                })

                if (response.status == 200) {
                    const formData = new FormData;
                    formData.append('order_id', localStorage.getItem('order_id'))
                    formData.append('payment_type', 'stripe')
                    formData.append('payment_id', response.data.clientSecret?.id)
                    formData.append('payment_status', 'paid')
                    formData.append('grand_total', parseFloat(MainTotal).toFixed(2))
                    try {
                        await axios({
                            method: "post",
                            url: `https://whanson.ztpl.net/backend/api/v2/final_order`,
                            data: formData,
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "multipart/form-data",
                            },
                        }).then(res => {
                            swal({
                                icon: 'success',
                                text: `Order Placed Successfully and Your Order ID will Be PFWH000000${localStorage.getItem('order_id')}`
                            }).then(data => {
                                localStorage.removeItem('order_id')
                                localStorage.removeItem('ship')
                                localStorage.removeItem('items')
                                navigate('/')
                            })
                        })
                    } catch (error) {
                        console.log(error)
                        setLoading(false)
                    }
                }
                setLoading(false)
            } catch (err) {
                console.log(err)
                setLoading(false)
            }
        } else {
            console.log(error)
        }
    }

    return (
        <div>
            {isLoading && (<Loader />)}
            {!success ? (

                <form onSubmit={handlePayment}>
                    <label>Card Number</label>
                    <CardElement />
                    <button className='ps-btn bg-blue color-white text-center  w-100' type="submit">
                        <span style={{ color: "rgb(255, 182, 0)", fontWeight: "650" }}> £{MainTotal.toFixed(2)}</span> -
                        Pay with Credit / Debit Card
                    </button>
                </form>
            ) : (
                <div>
                    <p>Payment Done Successfully</p>
                </div>
            )}

        </div>
    )
}

export default CheckoutForm