import React, { useEffect } from "react";
import PageContainer from "../../components/layouts/PageContainer";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import BreadCrumb from "../../components/elements/BreadCrumb";
import { Form, Input, Modal, Radio, Select, Switch } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Loader from "../Loader";
import swal from "sweetalert";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../Payment/CheckoutForm";

const Checkout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem("auth_id") &&
      !localStorage.getItem("auth_name")
    ) {
      navigate("/users/login");
    }
  }, []);

  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Checkout",
    },
  ];
  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;

  const [payment, setPayment] = useState(false);
  const [same_address, setSameAddress] = useState(true);
  const [method, setMethod] = useState(1);

  function handleChangeMethod(e) {
    setMethod(e.target.value); //e.target.value
  }

  const [data, setData] = useState(JSON.parse(localStorage.getItem("items")) || [])
  const [shippingfess, setShippingFees] = useState(localStorage.getItem("ship") || '')


  const sum = data.map((obj) => {
    return (parseFloat(obj.price) + (parseFloat(obj.price) * 20 / 100)) * parseFloat(obj.qty)
  })
  const subTotal = sum.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const vatPrice = parseFloat(subTotal) * 20 / 100

  const MainTotal = parseFloat(subTotal) + parseFloat(vatPrice) + parseFloat(shippingfess)


  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [billing_country, setBillingCountry] = useState('United Kingdom')
  const [billing_state, setBillingstate] = useState('')
  const [billing_city, setBillingCity] = useState('')
  const [billing_code, setBillingCode] = useState('')
  const [billing_address, setBillingAddress] = useState('')
  const [shipping_country, setshippingCountry] = useState('United Kingdom')
  const [shipping_state, setshippingstate] = useState('England')
  const [shipping_city, setshippingCity] = useState('')
  const [shipping_code, setshippingCode] = useState('')
  const [shipping_address, setShippingAddress] = useState('')

  useEffect(() => {
    onUserDetail();
  }, [])

  const [isLoading, setLoading] = useState(false)
  const [state_option, setStateOption] = useState([])
  const [city_ship_option, setCityShipOption] = useState([])
  const [city_option, setCityOption] = useState([])

  const onUserDetail = async () => {
    setLoading(true);
    try {
      await axios({
        method: "get",
        url: `https://whanson.ztpl.net/backend/api/v2/users/${localStorage.getItem("auth_id")}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setName(res.data.users[0].name)
        setEmail(res.data.users[0].email)
        setStateOption(res.data.states)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onChangeState = async (e) => {
    setLoading(true)
    setBillingstate(e.target.options[e.target.selectedIndex].id);
    try {
      await axios({
        method: "get",
        url: `https://whanson.ztpl.net/backend/api/v2/cities/${e.target.value}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setCityOption(res.data.cities)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const onChangeShippingState = async (e) => {
    setLoading(true)
    setshippingstate(e.target.options[e.target.selectedIndex].id);
    try {
      await axios({
        method: "get",
        url: `https://whanson.ztpl.net/backend/api/v2/cities/${e.target.value}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setCityShipOption(res.data.cities)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }


  const onFirstCheckout = async (e) => {
    e.preventDefault();
    const alProduct = JSON.parse(localStorage.getItem('items'))
    const ProductIds = alProduct.map((obj) => obj.id).join(",")
    const Widths = alProduct.map((obj) => obj.width).join(",")
    const Heights = alProduct.map((obj) => obj.height).join(",")
    const Lengths = alProduct.map((obj) => obj?.length).join(",")
    const Price = alProduct.map((obj) => ((parseFloat(obj.price) + (parseFloat(obj.price) * 20 / 100)) * parseFloat(obj.qty)).toFixed(2)).join(",")
    const Qty = alProduct.map((obj) => obj?.qty).join(",")

    let ShippingAddress;
    let BillingAddress;
    if (same_address == true) {
      BillingAddress = { address: billing_address, country: billing_country, state: billing_state, city: billing_city, postal_code: billing_code }

      ShippingAddress = { address: billing_address, country: billing_country, state: billing_state, city: billing_city, postal_code: billing_code }
    } else {
      BillingAddress = { address: billing_address, country: billing_country, state: billing_state, city: billing_city, postal_code: billing_code }

      ShippingAddress = { address: shipping_address, country: shipping_country, state: shipping_state, city: shipping_city, postal_code: shipping_code }
    }
    setLoading(true);
    const formData = new FormData;
    formData.append('user_id', localStorage.getItem('auth_id'))
    formData.append('product_id', ProductIds)
    formData.append('width', Widths)
    formData.append('height', Heights)
    formData.append('length', Lengths)
    formData.append('price', Price)
    formData.append('qty', Qty)
    formData.append('shipping_address', JSON.stringify(ShippingAddress))
    formData.append('billing_address', JSON.stringify(BillingAddress))
    try {
      await axios({
        method: "post",
        url: `https://whanson.ztpl.net/backend/api/v2/partial_order`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setShippingFees(res.data.shipping_cost)
        localStorage.setItem("order_id", res.data.order_id)
        localStorage.setItem("ship", res.data.shipping_cost)
        setPayment(true)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }


  const onFinalCheckout = async (e) => {
    swal({
      title: "Are you sure?",
      text: "You want to Placed an Order",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async willDelete => {
      if (willDelete) {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData;
        formData.append('order_id', localStorage.getItem('order_id'))
        formData.append('payment_type', 'cash_on_delivery')
        formData.append('payment_id', '')
        formData.append('payment_status', 'unpaid')
        formData.append('grand_total', parseFloat(MainTotal).toFixed(2))
        try {
          await axios({
            method: "post",
            url: `https://whanson.ztpl.net/backend/api/v2/final_order`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(res => {
            swal({
              icon: 'success',
              text: `Order Placed Successfully and Your Order ID will Be PFWH000000${localStorage.getItem('order_id')}`
            }).then(data => {
              setPayment(false)
              localStorage.removeItem('order_id')
              localStorage.removeItem('ship')
              localStorage.removeItem('items')
              navigate('/')
            })
            setLoading(false)
          })
        } catch (error) {
          console.log(error)
          setLoading(false)
        }
      }
    })
  }


  // Strip Payment Integration
  const [open, setOpen] = useState(false)

  const PUBLIC_KEY = "pk_test_51O9iUSSAu4SQbYbwKYY8lV7aGKR1h7xAZKxjBY8NAK3CtawTmoS5xb5CrN9Vxp5wbaectWNviMVnANiCae1ANvgg00VwGp0z2H"
  const stripePayment = loadStripe(PUBLIC_KEY)




  return (
    <div>
      {isLoading && (<Loader />)}
      <PageContainer header={headers} footer={footer} title="Register">
        <div className="ps-page--my-account">
          <BreadCrumb breacrumb={breadCrumb} />

          <div className="ps-checkout ps-section--shopping pt-60">
            <div className="container">
              <div
                className="ps-section__header "
                style={{ paddingBottom: "50px" }}
              >
                <h1 style={{ fontSize: "30px", textAlign: "left" }}>
                  Checkout Information
                </h1>
              </div>
              <div className="ps-section__content">
                <div className="ps-form--checkout">
                  <div className="ps-form__content">
                    <form onSubmit={onFirstCheckout}>
                      <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 custome-checkout" >
                          <div className="ps-form__billing-info">
                            <h3 className="ps-form__heading">
                              Contact information
                            </h3>
                            <div className="row">
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Full Name *"
                                    value={name}
                                    required
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    placeholder={"Email or phone number *"}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <h3 className="ps-form__heading mt-20">
                              Billing address
                            </h3>

                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <select className="form-control" value={billing_country} onChange={(e) => setBillingCountry(e.target.value)} required>
                                    <option value=''>Select Country *</option>
                                    <option value='United Kingdom'>United Kingdom</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <select className="form-control" id='mySelect' onChange={onChangeState} required>
                                    <option value=''>Select State *</option>
                                    {state_option.map((obj, i) => {
                                      return (
                                        <option value={obj.id} id={obj.name} key={i}>{obj.name}</option>
                                      )
                                    })}

                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <select className="form-control" required onChange={(e) => setBillingCity(e.target.value)}>
                                    <option value=''>Select City *</option>
                                    {city_option.map((obj, i) => {
                                      return (
                                        <option value={obj.name} key={i}>{obj.name}</option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <Input
                                    className="form-control"
                                    type="postalCode"
                                    placeholder="Postal Code *"
                                    onChange={(e) => setBillingCode(e.target.value)}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <Input
                                className="form-control"
                                type="text"
                                placeholder="Address *"
                                onChange={(e) => setBillingAddress(e.target.value)}
                                required
                              />
                            </div>
                            <div className="form-group mt-20">
                              <div className="">
                                <Switch
                                  checked={same_address}
                                  onChange={(e) => setSameAddress(e)}
                                />
                                <label
                                  htmlFor="save-information"
                                  className="ml-20"
                                >
                                  Same Address in Shipping Details
                                </label>
                              </div>
                            </div>

                            {/* Shipping Address Details */}

                            {same_address == false ? (
                              <>
                                <h3 className="ps-form__heading mt-20">
                                  Shipping Address
                                </h3>

                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <select className="form-control" value={shipping_country} onChange={(e) => setshippingCountry(e.target.value)}>
                                        <option value=''>Select Country *</option>
                                        <option value='United Kingdom'>United Kingdom</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <select className="form-control" onChange={onChangeShippingState} required>
                                        <option value=''>Select State *</option>
                                        {state_option.map((obj, i) => {
                                          return (
                                            <option value={obj.id} key={i}>{obj.name}</option>
                                          )
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <select className="form-control" required onChange={(e) => setshippingCity(e.target.value)}>
                                        <option value=''>Select City *</option>
                                        {city_ship_option.map((obj, i) => {
                                          return (
                                            <option value={obj.name} key={i}>{obj.name}</option>
                                          )
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <Input
                                        className="form-control"
                                        type="postalCode"
                                        placeholder="Postal Code *"
                                        onChange={(e) => setshippingCode(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Address *"
                                    onChange={(e) => setShippingAddress(e.target.value)}
                                  />
                                </div>
                              </>
                            ) : null}

                            <div className="ps-form__submit">
                              <Link to="/shopping-cart">
                                <a>
                                  <i className="icon-arrow-left mr-2"></i>
                                  Return to Shopping Cart
                                </a>
                              </Link>
                            </div>


                          </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12  ps-block--checkout-order custome-order">
                          <div className="ps-form__orders">
                            <h3>Your order</h3>
                            <div className="ps-block--checkout-order">
                              <div className="ps-block__content">
                                <figure className="mb-20">
                                  <figcaption>
                                    <strong>Product</strong>
                                    <strong>total</strong>
                                  </figcaption>
                                </figure>
                                {data?.map((item, i) => {
                                  const total = parseFloat(item.price) * parseFloat(item.qty)
                                  return (
                                    <figure className="ps-block__items pb-0">
                                      <div className="d-flex justify-space-between">
                                        <a className="col-9 ">
                                          <strong>
                                            {item.name}
                                            <span>x {item.qty}</span>
                                            {item.type == "Linear" ? (
                                              <p className="varition-size"> H : {item.height}m , W : {item.width}m , L : {item.length}m </p>
                                            ) : null}
                                          </strong>
                                        </a>
                                        <a className="col-2 justify-content-center">
                                          <small>£{total?.toFixed(2)}</small>
                                        </a>
                                      </div>
                                    </figure>
                                  )
                                })}

                                <figure className="mt-30">
                                  <figcaption>
                                    <strong>Subtotal</strong>
                                    <small>
                                      <strong>£{subTotal?.toFixed(2)}</strong>
                                    </small>
                                  </figcaption>
                                </figure>
                                {payment || localStorage.getItem('order_id') != null ? (
                                  <>
                                    <figure >
                                      <figcaption>
                                        <strong>VAT</strong>
                                        <small>
                                          <strong>£{vatPrice.toFixed(2)}</strong>
                                        </small>
                                      </figcaption>
                                    </figure>
                                    <figure>
                                      <figcaption>
                                        <strong>Shipping Fee</strong>
                                        <small>
                                          <strong>£{parseFloat(shippingfess).toFixed(2)}</strong>
                                        </small>
                                      </figcaption>
                                    </figure>
                                    <figure className="ps-block__total mb-0 pb-0 border-bottom-none">
                                      <h3>
                                        Grand Total
                                        <strong>£{MainTotal.toFixed(2)}</strong>
                                      </h3>
                                    </figure>

                                  </>
                                ) : null}
                              </div>
                            </div>

                            {localStorage.getItem('order_id') != null ? null : (
                              <div className="ps-block__footer" >
                                <button className="ps-btn bg-blue color-white w-100">
                                  Cotinue with Payment
                                </button>
                              </div>
                            )}

                            {payment || localStorage.getItem('order_id') != null ? (
                              <div className="mt-20">
                                <h4 className="mb-15">Payment Methods</h4>
                                <div className="ps-block--payment-method" >
                                  <div className="ps-block__header">
                                    <Radio.Group
                                      onChange={(e) => handleChangeMethod(e)}
                                      value={method}
                                    >
                                      <Radio value={1}>Cash on Delivery</Radio>
                                      <Radio value={3}>Credit / Debit Card</Radio>
                                    </Radio.Group>
                                  </div>
                                </div>
                                <div className="ps-block__footer" >

                                  {method == 1 ?
                                    (
                                      <a className="ps-btn bg-blue color-white text-center  w-100" onClick={onFinalCheckout}>
                                        <span style={{ color: "rgb(255, 182, 0)", fontWeight: "650" }}> £{MainTotal.toFixed(2)}</span> -
                                        Pay on Delivery
                                      </a>
                                    ) : method == 3 ? (
                                      <a className="ps-btn bg-blue color-white text-center  w-100" onClick={() => {
                                        swal({
                                          title: "Are you sure?",
                                          text: "You want to Placed an Order",
                                          icon: "warning",
                                          buttons: true,
                                          dangerMode: true,
                                        }).then(async willDelete => {
                                          if (willDelete) {
                                            setOpen(true)
                                          }
                                        })
                                      }
                                      }>
                                        <span style={{ color: "rgb(255, 182, 0)", fontWeight: "650" }}> £{MainTotal.toFixed(2)}</span> -
                                        Pay with Credit / Debit Card
                                      </a>
                                    ) : null}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </PageContainer >


      <Modal
        open={open}
        title="Stripe Payment"
        centered
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={<div className="footer-none"></div>}
      >
        <Elements stripe={stripePayment}>
          <CheckoutForm MainTotal={MainTotal} />
        </Elements>
      </Modal>
    </div >
  );
};

export default Checkout;
