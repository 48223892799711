import React from 'react'
import { loadStripe } from '@stripe/stripe-js';
import {
    PaymentElement,
    Elements,
    CardElement,
} from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

const StripeContainer = () => {
    const PUBLIC_KEY = "pk_test_51O9iUSSAu4SQbYbwKYY8lV7aGKR1h7xAZKxjBY8NAK3CtawTmoS5xb5CrN9Vxp5wbaectWNviMVnANiCae1ANvgg00VwGp0z2H"
    const stripePayment = loadStripe(PUBLIC_KEY)


    return (
        <div>
            <Elements stripe={stripePayment}>
                <CheckoutForm/>
            </Elements>
        </div>
    )
}

export default StripeContainer