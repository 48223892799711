import axios from 'axios';
import React, { useState } from 'react'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Loader from '../Loader';
import config from '../../config';
import NoImg from "../../assets/static/img/w-logo.jpg"

const LightBoxicon = ({ productId }) => {

    const [all_images, setAllImages] = useState([])
    const [light_open, setLightOpen] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const handleOpenLightbox = async () => {
        setLoading(true)
        try {
            await axios({
                method: "get",
                url: `${config.apiUrl}/images/${productId}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then(res => {
                setAllImages(res.data)
                setLightOpen(true);
                setLoading(false)
            })
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    };


    return (
        <div>
            {isLoading && (<Loader />)}
            <ul className="ps-product__actions ps-product__thumbnail">
                <li>
                    <a
                        style={{ color: 'black' }}
                        onClick={handleOpenLightbox}
                    >
                        <i className="icon-eye"></i>
                    </a>

                </li>
            </ul>
            <Lightbox
                open={light_open}
                close={() => setLightOpen(false)}
                slides={all_images?.length != 0 ? all_images?.map(image => ({ src: image, title: image })) : [{ src: NoImg, title: NoImg }]}
            />

        </div>
    )
}

export default LightBoxicon