import React, { useEffect, useState } from "react";
import HomeDefaultDealOfDay from './HomeDefaultDealOfDay';
import PageContainer from "../../components/layouts/PageContainer";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import { Link, useNavigate } from "react-router-dom";
import Brand1 from "../../assets/static/img/brand-1.png"
import Brand2 from "../../assets/static/img/brand-2.png"
import Brand3 from "../../assets/static/img/brand-3.png"
import Brand4 from "../../assets/static/img/brand-4.png"
import TopCategories from "./TopCategories";
import CTASection from "./CTASection";
import Banner1 from "../../assets/static/img/category/Banner1.jpg"
import Banner2 from "../../assets/static/img/category/Banner2.jpg"
import axios from "axios";
import config from "../../config";

const HomeMarketPlacePage = () => {

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (
  //     !localStorage.getItem("auth_user")
  //   ) {
  //     navigate("/login");
  //   }
  // }, []);

  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;

  const [isLoading, setLoading] = useState(false);
  const [image, setImages] = useState([]);

  useEffect(() => {
    const fetchImage = async () => {
      setLoading(true);
      const formData = new FormData();
      formData.append("type", 1);
      try {
        await axios({
          method: "post",
          data: formData,
          url: `${config.apiUrl}/offer_image`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {
          setImages(res.data.data);
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    if (image?.length == 0) {
      fetchImage();
    }
  }, []);

  return (
    <PageContainer header={headers} footer={footer} title="Home Market Place">
      <main id="homepage-3">
        <section className="mt-70 ">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                <Link to="/bespoke-machine-service" className="ps-collection mb-30" >
                  <img src={Banner1} alt="martfury" style={{ objectFit: "cover" }} />
                </Link>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                <Link to="/offers" className="ps-collection mb-30" href="#">
                  <img src={image?.offer} alt="martfury" style={{ objectFit: "cover" }} />
                </Link>
              </div>
            </div>
          </div>
        </section>

        <TopCategories />
        <CTASection image={image} />
        <HomeDefaultDealOfDay collectionSlug="deal-of-the-day" />

        <div className="pt-70 pb-50">
          <div className="ps-best-sale-brands ps-section--furniture pt-10">
            <div className="container">
              <div className="ps-section__header"><h3>BEST SELLER BRANDS</h3></div>
              <div className="ps-section__content">
                <ul className="ps-image-list">
                  <li className="" style={{ minWidth: "25%", height: "130px" }}>
                    <Link href="/">
                      <a>
                        <img src={Brand1} alt="No Img" width='200' />
                      </a>
                    </Link>{' '}
                  </li>
                  <li style={{ minWidth: "25%", height: "130px" }}>
                    <Link href="/">
                      <a>
                        <img src={Brand2} alt="No Img" width='200' />
                      </a>
                    </Link>{' '}
                  </li>
                  <li style={{ minWidth: "25%", height: "130px" }}>
                    <Link href="/">
                      <a>
                        <img src={Brand3} alt="No Img" width='200' />
                      </a>
                    </Link>{' '}
                  </li>
                  <li style={{ minWidth: "25%", height: "130px" }}>
                    <Link href="/">
                      <a>
                        <img src={Brand4} alt="No Img" />
                      </a>
                    </Link>{' '}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </PageContainer>
  );
};
export default HomeMarketPlacePage;
