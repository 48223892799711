import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import { Link, useNavigate } from "react-router-dom";
import PageContainer from "../../components/layouts/PageContainer";
import BreadCrumb from "../../components/elements/BreadCrumb";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import { Modal } from "antd";
import logo from "../../assets/static/img/w-logo.jpg";
import swal from "sweetalert";

const CartView = () => {

  // useEffect(() => {
  //   if (
  //     !localStorage.getItem("auth_user")
  //   ) {
  //     navigate("/login");
  //   }
  // }, []);


  const [quantity, setQuantity] = useState([]);
  const [data, setData] = useState([]);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setData(JSON.parse(localStorage.getItem("items")));
    setClicked(false);

    setQuantity(
      Array.from(
        { length: JSON.parse(localStorage.getItem("items"))?.length },
        () => 1
      )
    );
  }, [clicked]);

  const items = data?.map((item, i) => {

    const VatPrice = (parseFloat(item.price) * 20 / 100)

    const Total =
      (parseFloat(item.price) + VatPrice) * item.qty;

    return (
      <tr key={item.id}>
        <td>
          <div className="row align-items-center">
            <div className="col-3 text-center">
              <Link to={`/product/${item.slug}`}>
                <img src={item.thumbnail_image != null ? item.thumbnail_image : logo} height='70' />
              </Link>
            </div>
            <div className="col-9 pl-0 pr-0">{item.name}
              {item.type == "Linear" ? (

                <p className="varition-size"> H : {item.height}m , W : {item.width}m , L : {item.length}m </p>
              ) : null}
            </div>
          </div>
        </td>
        <td data-label="price" className="price">
          £{item.price}
        </td>
        <td data-label="price" className="price">
          £{VatPrice?.toFixed(2)}
        </td>
        <td data-label="quantity">
          <div className="form-group--number">
            <button className="up" onClick={() => {
              const updatedArray = data.map(obj => {
                if (obj.id === item.id) {
                  return { ...obj, qty: item.qty + 1 };
                }
                return obj;
              });
              localStorage.setItem('items', JSON.stringify(updatedArray))
              setClicked(true)
            }}>
              +
            </button>
            <button className="down"

              onClick={() => {
                if (item.qty > 1) {
                  const updatedArray = data.map(obj => {
                    if (obj.id === item.id) {
                      return { ...obj, qty: item.qty - 1 };
                    }
                    return obj;
                  });
                  localStorage.setItem('items', JSON.stringify(updatedArray))
                  setClicked(true)
                }
              }}>
              -
            </button>
            <input
              className="form-control"
              type="text"
              value={item.qty}
              disabled={true}
            />
          </div>
        </td>
        <td data-label="total">
          <strong>
            £
            {Total?.toFixed(2)}
          </strong>
        </td>
        <td className="text-center">
          <a className="btn-trash bg-danger" onClick={() => {
            swal({
              icon: "warning",
              title: "Are You sure ?",
              text: "You want to remove this item from Cart",
              buttons: true,
            }).then((willDelete) => {
              if (willDelete) {
                setClicked(true);
                localStorage.setItem(
                  "items",
                  JSON.stringify(
                    data.filter(
                      (obj) => obj.id != item.id
                    )
                  )
                );
              }
            });
          }}>
            <i className="icon-trash2"></i>
          </a>
        </td>
      </tr >
    );
  });

  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Shopping Cart",
    },
  ];
  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;

  const [open, setOpen] = useState(false);
  const [loginModal, setLoginModal] = useState(false);

  const onRedirect = () => {
    setOpen(false);
    if (
      !localStorage.getItem("auth_id") &&
      !localStorage.getItem("auth_name")
    ) {
      setLoginModal(true);
    } else {
      navigate('/checkout')
    }
  };

  const navigate = useNavigate();
  return (
    <div>
      <PageContainer header={headers} footer={footer} title="Register">
        <div className="ps-page--my-account">
          <BreadCrumb breacrumb={breadCrumb} />

          <div className="mb-70 mt-70 bg-white">
            <div className="container">
              <div className="ps-section__content">
                <table className="table  ps-table--shopping-cart ps-table--responsive">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Price</th>
                      <th>VAT</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{items}
                    {data?.length == 0 || data == null ? (

                      <td colSpan='6'>
                        <p className="text-center mt-20">No Product in Added</p>
                      </td>
                    ) : null}
                  </tbody>
                </table>
                <div className="row justify-space-between mt-30">
                  <div className="ps-section__cart-actions pl-20">
                    <a onClick={() => navigate(-1)}>
                      <i className="icon-arrow-left mr-2"></i>Return to shop
                    </a>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 ">
                    <a
                      onClick={() => setOpen(true)}
                      className="ps-btn ps-btn--fullwidth bg-blue color-white"
                    >
                      Proceed to Checkout
                    </a>
                  </div>
                </div>
              </div>
              <div className="ps-section__footer">
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>

      <Modal
        open={open}
        title="Rules of Refund"
        centered
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={<div className="footer-none"></div>}
      >
        <ol className="pl-20  mb-30">
          <li className="mb-10">
            Products marked as "non-returnable" on the product detail page
            cannot be returned.
          </li>
          <li className="mb-10">
            No additional information is required to return an eligible order
            unless otherwise noted in the category-specific policy.
          </li>
          <li className="mb-10">
            Products may be eligible for replacement only if the same seller has
            the exact same item in stock.
          </li>
          <li className="mb-10">
            If the replacement request is placed and the seller does not have
            the exact same product in stock, a refund would be issued to you.
          </li>
        </ol>
        <div className="text-center mb-20 ">
          <a
            className="btn-ligth-blue p-4 mr-10"
            onClick={() => setOpen(false)}
          >
            Back to Shopping
          </a>
          <a onClick={onRedirect} className="btn-blue p-4 ml-10">
            Procced to Checkout
          </a>
        </div>
      </Modal>

      <Modal
        open={loginModal}
        centered
        onOk={() => setLoginModal(false)}
        onCancel={() => setLoginModal(false)}
        footer={<div className="footer-none"></div>}
        header={<></>}
        width={400}
      >
        <div className="text-center mb-20">
          <img src={logo} className="" style={{ width: "40%" }} alt="No img" />
        </div>
        <div className="text-center mb-20 ">
          <div className="mb-20">
            <button
              className="btn-ligth-blue p-4  w-100"
              onClick={() => {
                navigate("/users/guest-checkout");
              }}
              style={{ border: "none" }}
            >
              <i
                className="icon-user pr-10"
                style={{ fontSize: "17px", lineHeight: "0" }}
              ></i>
              Continue with Guest User
            </button>
          </div>
          <div>
            <button
              onClick={() => {
                navigate("/users/login");
              }}
              className="btn-blue p-4  w-100"
              style={{ border: "none" }}
            >
              <i
                className="icon-user-lock pr-10"
                style={{ fontSize: "17px", lineHeight: "0" }}
              ></i>
              Continue with Login
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CartView;
