import React from 'react'
import PageContainer from '../../components/layouts/PageContainer'
import BreadCrumb from '../../components/elements/BreadCrumb'
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import { useRef } from 'react';

const Career = () => {
    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "Career",
        },
    ];


    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;

    const formRef = useRef(null)

    return (
        <div>
            <PageContainer header={headers} footer={footer} title="Register">
                <div className="ps-page--single">
                    <img src="https://media.istockphoto.com/id/644335608/photo/personal-development-self-improvement-and-career-growth.jpg?b=1&s=170667a&w=0&k=20&c=V48HbKg-U9iMxOWkyg_G6Kx4MxxS7yg9K69pNd1vhiQ=" height="300" style={{ width: "100%", objectFit: "cover", }} alt="" />
                    <BreadCrumb breacrumb={breadCrumb} />
                    <div className="ps-section--custom pb-0">
                        <div className="container ">
                            <div className='row justify-content-center pt-90 pb-70'>
                                <div className='col-10 text-center career-text'>
                                    <h5>Job Vacancies</h5>
                                    <h1 className='mb-5'>
                                        Examine Potential, Develop Your Career, Design Your Professional Path, and Move Forward: Jobs at Whanson Group For Career Opportunities
                                    </h1>
                                    <p>As we embark on our journey towards innovation and excellence, we invite passionate and talented individuals to join our dynamic team, where collaboration, creativity, and a commitment to making a positive impact converge to create a workplace that fosters personal growth, professional development, and the pursuit of shared goals, shaping not only careers but a collective vision for a brighter and more impactful future.</p>

                                    <div className="form-group submit mt-5" ref={formRef}>
                                        <button
                                            type="submit"
                                            className="ps-btn ps-btn--fullwidth bg-blue color-white col-4"
                                            onClick={() => formRef?.current?.scrollIntoView({ behavior: 'smooth' })}
                                        >
                                            Join Us Now
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='form-bg-blue'>
                            <div className='container'>
                                <div className=' row justify-content-center pt-50 pb-50' >
                                    <form className="ps-form--contact-us col-md-9 career-form" action="/" method="get">
                                        <h1 className='text-center mb-5 pb-5'>Fill the Form below for Indicating Your Potentails</h1>
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Enter Name *"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Enter Email *"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Enter Phone Number *"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Enter Position *"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Enter Expected CTC *"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        placeholder="Enter Position *"
                                                        style={{ paddingTop: '14px' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                <div className="form-group">
                                                    <textarea
                                                        className="form-control"
                                                        rows="5"
                                                        placeholder="Write a Description"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group submit ">
                                            <button className="ps-btn bg-blue color-white">Send message</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer >
        </div >
    )
}

export default Career