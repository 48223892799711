import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/styles/style.scss";
import "./assets/static/fonts/Linearicons/Font/demo-files/demo.css";
import "./assets/static/fonts/font-awesome/css/font-awesome.min.css";
import "./assets/static/css/bootstrap.min.css";
import "./assets/static/css/slick.min.css";
import "./assets/styles/home-default.scss";
import "./assets/styles/market-place-1.scss";
import "./assets/styles/Custom.css";
import HomeMarketPlacePage from "./pages/home/market-place";
import Categories from "./pages/category/Category";
import ProductDetail from "./pages/product/ProductDetail";
import LoginPage from "./pages/account/login";
import RegisterPage from "./pages/account/register";
import CartView from "./pages/Cart/CartView";
import Checkout from "./pages/Cart/Checkout";
import Shipping from "./pages/Cart/Shipping";
import Payment from "./pages/Cart/Payment";
import Terms from "./pages/Terms&Conditions/Terms";
import ReturnPolicy from "./pages/Terms&Conditions/ReturnPolicy";
import PrivacyPolicy from "./pages/Terms&Conditions/PrivacyPolicy";
import ChainofCustody from "./pages/Terms&Conditions/ChainofCustody";
import Serach from "./pages/search/Search";
import Trafficking from "./pages/Terms&Conditions/Trafficking";
import Contact from "./pages/account/Contact";
import AboutUs from "./pages/account/AboutUs";
import Dashboard from "./pages/Dashboard-Panel/Dashboard";
import GuestCheckout from "./pages/account/GuestCheckout";
import AdminLogin from "./pages/account/AdminLogin";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import StripeContainer from "./pages/Payment/StripeContainer";
import Customised from "./pages/Terms&Conditions/Customised";
import Career from "./pages/Terms&Conditions/Career";
import SpotDeals from "./pages/Offers/SpotDeals";
import Offers from "./pages/Offers/Offers";
import SpotDealsCategory from "./pages/Offers/SpotDealCategories";
import FAQ from "./pages/Terms&Conditions/FAQ";
import ProductNews from "./pages/Terms&Conditions/ProductNews";
import StockClearance from "./pages/Terms&Conditions/StockClearance";
import SecureLogin from "./pages/account/SecureLogin";

function App() {
    return (
        <BrowserRouter>
            {/* <Routes>
                <Route path="/login" Component={SecureLogin} />
            </Routes> */}
            <Routes>
                <Route path="/" Component={HomeMarketPlacePage} />
            </Routes>
            <Routes>
                <Route path="/category/:slug" Component={Categories} />
            </Routes>
            <Routes>
                <Route path="/product/:slug" Component={ProductDetail} />
            </Routes>
            <Routes>
                <Route path="/users/login" Component={LoginPage} />
            </Routes>
            <Routes>
                <Route path="/users/registration" Component={RegisterPage} />
            </Routes>
            <Routes>
                <Route path="/shopping-cart" Component={CartView} />
            </Routes>
            <Routes>
                <Route path="/checkout" Component={Checkout} />
            </Routes>
            <Routes>
                <Route path="/checkout/delivery_info" Component={Shipping} />
            </Routes>
            <Routes>
                <Route path="/checkout/payment" Component={Payment} />
            </Routes>
            <Routes>
                <Route path="/Terms-&-Conditions" Component={Terms} />
            </Routes>
            <Routes>
                <Route path="/return-policy" Component={ReturnPolicy} />
            </Routes>
            <Routes>
                <Route path="/privacy-policy" Component={PrivacyPolicy} />
            </Routes>
            <Routes>
                <Route
                    path="/Chain-of-Custody.html"
                    Component={ChainofCustody}
                />
            </Routes>
            <Routes>
                <Route
                    path="/slavery-human-trafficking"
                    Component={Trafficking}
                />
            </Routes>
            <Routes>
                <Route path="/bespoke-machine-service" Component={Customised} />
            </Routes>
            <Routes>
                <Route
                    path="/Contact-W.Hanson-for-all-your-timber-&-building-requiremetns.html"
                    Component={Contact}
                />
            </Routes>
            <Routes>
                <Route path="/about-us" Component={AboutUs} />
            </Routes>
            <Routes>
                <Route path="/search/" Component={Serach} />
            </Routes>

            <Routes>
                <Route path="/profile" Component={Dashboard} />
            </Routes>

            <Routes>
                <Route path="/users/guest-checkout" Component={GuestCheckout} />
            </Routes>

            <Routes>
                <Route path="/payment" Component={StripeContainer} />
            </Routes>
            <Routes>
                <Route path="/Recruitment.html" Component={Career} />
            </Routes>
            <Routes>
                <Route path="/spot-deals" Component={SpotDeals} />
            </Routes>
            <Routes>
                <Route
                    path="/spot-deals-category"
                    Component={SpotDealsCategory}
                />
            </Routes>
            <Routes>
                <Route path="/offers" Component={Offers} />
            </Routes>
            <Routes>
                <Route
                    path="/Frequently-Asked-Questions-about-the-W.Hanson-Group-of-Timber-&-Builders-Merchants.html"
                    Component={FAQ}
                />
            </Routes>
            <Routes>
                <Route path="/Product-News.html" Component={ProductNews} />
            </Routes>
            <Routes>
                <Route
                    path="/Stock-Clearance-at-W.Hanson-(Iron-Bridge)-Ltd.html"
                    Component={StockClearance}
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
