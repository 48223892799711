import React, { useEffect } from 'react'
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import PageContainer from '../../components/layouts/PageContainer';
import BreadCrumb from '../../components/elements/BreadCrumb';
import { useNavigate } from 'react-router-dom';

const ReturnPolicy = () => {

    // const navigate = useNavigate();
    // useEffect(() => {
    //   if (
    //     !localStorage.getItem("auth_user")
    //   ) {
    //     navigate("/login");
    //   }
    // }, []);

    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "Returns Policy",
        },
    ];
    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;
    return (
        <div>
            <PageContainer header={headers} footer={footer} title="Register">
                <div className="ps-page--my-account">
                    <BreadCrumb breacrumb={breadCrumb} />
                    <div className="ps-section--custom">
                        <div className="container">
                            <div className="ps-section__header">
                                <h1 className="text-center " style={{ fontWeight: "550" }}>RETURNS POLICY</h1>
                            </div>
                            <div className="ps-section__content policy-section mt-20">
                                <ol>
                                    <li>W.Hanson must be notified of any refunds within 14 days of purchase.</li>
                                    <li>All refunds can only be processed with proof of purchase.</li>
                                    <li>No sand or powder products can be refunded due to risk of contamination.</li>
                                    <li>Goods must be in resalable condition in original packaging, unused and undamaged.</li>
                                    <li>Good collected by W Hanson will incur a minimum 30% restocking charge – this may vary due to location & product.</li>
                                    <li>Good returned to W Hanson will incur a 20% restocking charge. </li>
                                    <li>Any specially machined products cannot be refunded once produced.</li>
                                    <li>Refunds can only we made to the original card of purchase.</li>
                                </ol>
                            </div>
                        </div>

                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default ReturnPolicy