import React from 'react'
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import PageContainer from '../../components/layouts/PageContainer';
import BreadCrumb from '../../components/elements/BreadCrumb';
import { Helmet } from 'react-helmet';

const StockClearance = () => {
    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: " Stock Clearance",
        },
    ];
    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;

    return (
        <div>
            <Helmet>
                <title>W.Hanson Group - Stock Clearance at W.Hanson (Iron Bridge) Ltd</title>
                <meta NAME="Keywords" Content="Timber Merchant, Builders Merchant, Timber Merchants, Builders merchants, Timber supplies London, Building Supplies London, Timber Merchant Southall, Builders Merchant Southall, Timber Merchant West London, Builders Merchant West London, Special Moulding London, Machined Timber London, Machined Timber Southall, softwood London, Hardwood London, Softwood Southall, Hardwood Southall , Softwood London, Hardwood London, Skirting, Architrave, MDF, Plywood, Veneered MDF, , timber supplies, building supplies, merchant, free delivery , mdf mouldings, skirting , architrave, machined, diy, west london, Stock clearance, Cost price, Sikkens, cheap sikkens, cheapest sikkens, Timber Merchant Southall, Builders merchant greenford, Timber Merchant Greenford, timber merchant ealing, builders merchant ealing, ealing timber, greenford timber, southall timber, Builders Merchant Southall, Timber Merchant West London, Builders Merchant West London, Timber Merchant Middlesex, Builders Merchant Middlesex, Timber Merchants Middlesex, Builders merchants Middlesex, Timber supplies London Middlesex, Building Supplies London Middlesex" />
                <meta NAME="Description" Content="At W.Hanson (Iron Bridge) Ltd, we are constantly monitoring our stock for both price & customer requirements. As a result, we ocassionally have some unbeatable deals on clearance stock. You won't find better prices anywhere else!" />
            </Helmet>
            <PageContainer header={headers} footer={footer} title="Register">
                <div className="ps-page--single">
                    <BreadCrumb breacrumb={breadCrumb} />
                    <div className="container">
                        <div className='text-center col-9 mt-5 mb-5 pt-5 pb-5' style={{ margin: "auto" }}>
                            <h1 style={{ fontSize: "44px", marginBottom: '20px' }}>Clearance Corner Sheet Materials.</h1>
                            <h2 style={{ fontSize: "30px", marginBottom: '25px' }}>Both our Iron Bridge & Harrow depots have a large clearance corner section of damaged sheet material that we are keen to shift.</h2>
                            <h3 style={{ fontSize: "25px", marginBottom: '25px' }}><strong>Stunning deals</strong> ready to be done.</h3>
                            <h3 style={{ fontSize: "25px", marginBottom: '25px' }}>Speak to one of our Sales Team now! </h3>

                            <p style={{ fontSize: "20px", marginBottom: '15px' }}>Iron Bridge : 020 8571 3161</p>
                            <p style={{ fontSize: "20px", marginBottom: '15px' }}>Harrow   :  020 8864 0822</p>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default StockClearance