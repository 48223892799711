import React, { useState } from 'react'
import MobileHeaderActions from './modules/MobileHeaderActions';
import { Link } from 'react-router-dom';
import Logo from "../../../assets/static/img/w-hanson-logo-white.png";
import { Drawer, Menu } from 'antd';
import Wlogo from "../../../assets/static/img/w-logo.jpg"
import SubMenu from 'antd/lib/menu/SubMenu';

const HeaderMobile = () => {

    const menuData = {
        menuPrimary: {
            menu_1: [
                {
                    text: "Softwood",
                    url: "/category/Softwood",
                    extraClass: "menu-item-has-children",
                    subClass: "sub-menu",
                    subMenu: [
                        {
                            text: "Cheshire Mouldings",
                            url: "/category/cheshire-mouldings",
                        },
                        {
                            text: "Firrings /Arris/ Featheredge /Fencing",
                            url: "firrings",
                        },
                        {
                            text: "Thermowood",
                            url: "/category/thermowood",
                        },
                        {
                            text: "Pellets",
                            url: "/category/pellets",
                        },
                        {
                            text: "C24 Softwood Treated",
                            url: "/category/c24-softwood-treated",
                        },
                        {
                            text: "Home Grown Eased Edge KD Treated",
                            url: "/category/home_grown_eased_edge_kd_treated",
                        },
                    ],
                },
                {
                    text: "MDF Mouldings",
                    url: "/",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [
                        {
                            text: "MDF Skirting & Archtrave",
                            url: "/mdf-skirting-&-archtrave",
                        },
                        {
                            text: "MDF Window Boards",
                            url: "/mdf-window-boards",
                        },
                        {
                            text: "MDF Door Stop & Misc",
                            url: "/",
                        },
                        {
                            text: "MDF Linings",
                            url: "/",
                        },
                    ],
                },
                {
                    text: "Insulation",
                    url: "",
                    extraClass: "menu-item-has-children insulation",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [
                        {
                            text: "Celotex",
                            url: "/",
                        },
                        {
                            text: "Fibreglass",
                            url: "/",
                        },
                        {
                            text: "Cavity Insulation",
                            url: "/",
                        },
                        {
                            text: "Polystyrene",
                            url: "/",
                        },
                    ],
                },
                {
                    text: "Sheet Material",
                    url: "/",
                    current: "shop",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [
                        {
                            text: "Birch Plywood",
                            url: "/",
                        },
                        {
                            text: "Hardwood Throughout Plywood",
                            url: "/",
                        },
                        {
                            text: "Hardwood Throughout Plywood- FSC",
                            url: "/",
                        },
                        {
                            text: "Shuttering Plywood",
                            url: "/",
                        },
                        {
                            text: "Structural Plywood",
                            url: "/",
                        },
                        {
                            text: "Marine Plywood",
                            url: "/",
                        },
                        {
                            text: "OSB",
                            url: "/",
                        },
                        {
                            text: "MDF",
                            url: "/",
                        },
                        {
                            text: "Moisture Resistant MDF",
                            url: "/",
                        },
                        {
                            text: "Chipboard",
                            url: "/",
                        },
                        {
                            text: "Hardboard",
                            url: "/",
                        },
                        {
                            text: "Decorative MDF",
                            url: "/",
                        },
                        {
                            text: "Hardie Backer",
                            url: "/",
                        },
                        {
                            text: "Versafire",
                            url: "/",
                        },
                        {
                            text: "Iron On Edging",
                            url: "/",
                        },
                        {
                            text: "Melamine Faced MDF",
                            url: "/",
                        },
                        {
                            text: "Flexcell",
                            url: "/",
                        },
                        {
                            text: "Correx/ Protection",
                            url: "/",
                        },
                    ],
                },
                {
                    text: "Glues Sealants & Chemicals",
                    url: "",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [
                        {
                            text: "CT1",
                            url: "/",
                        },
                        {
                            text: "Evo-Stik",
                            url: "/",
                        },
                        {
                            text: "Bond It",
                            url: "/",
                        },
                        {
                            text: "OB1",
                            url: "/",
                        },
                    ],
                },
                {
                    text: "Metalwork",
                    url: "",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [
                        {
                            text: "Parkes Products Joist Hangers",
                            url: "/",
                        },
                        {
                            text: "Parkes Products Jiffy Hangers",
                            url: "/",
                        },
                        {
                            text: "Parkes Products Beading",
                            url: "/",
                        },
                        {
                            text: "Ties",
                            url: "/",
                        },
                        {
                            text: "Parkes Products Restraint Straps",
                            url: "/",
                        },
                        {
                            text: "Reinforcement Mesh",
                            url: "/",
                        },
                        {
                            text: "Metalwork Misc",
                            url: "/",
                        },
                    ],
                },
                {
                    text: "Aggregates & Heavyside",
                    url: "",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [
                        {
                            text: "Bulk Bags",
                            url: "/",
                        },
                        {
                            text: "Mini Bags",
                            url: "/",
                        },
                        {
                            text: "Cement",
                            url: "/",
                        },
                        {
                            text: "Top Soil",
                            url: "/",
                        },
                        {
                            text: "Air Bricks",
                            url: "/",
                        },
                        {
                            text: "Aco Channel",
                            url: "/",
                        },
                        {
                            text: "Metal Stud & Track/ Suspended Ceiling",
                            url: "/",
                        },
                    ],
                },
                {
                    text: "Fixings",
                    url: "/",
                    current: "shop",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [
                        {
                            text: "TIMco Firmahold",
                            url: "/",
                        },
                        {
                            text: "TIMco Nails",
                            url: "/",
                        },
                        {
                            text: "TIMco Screws",
                            url: "/",
                        },
                        {
                            text: "TIMco Shield Protective Film",
                            url: "/",
                        },
                        {
                            text: "TIMco Cutting",
                            url: "/",
                        },
                        {
                            text: "TIMco Drilling and Driver Bits",
                            url: "/",
                        },
                        {
                            text: "TIMco Misc",
                            url: "/",
                        },
                        {
                            text: "Timco Bags",
                            url: "/",
                        },
                        {
                            text: "Timco Coach Bolts",
                            url: "/",
                        },
                        {
                            text: "Samac Screws",
                            url: "/",
                        },
                        {
                            text: "Drywall Screws",
                            url: "/",
                        },
                        {
                            text: "Studding",
                            url: "/",
                        },
                        {
                            text: "Loose Nails",
                            url: "/",
                        },
                        {
                            text: "Plasterboard/ Rawlplug Fixings",
                            url: "/",
                        },
                        {
                            text: "Masonry Nails",
                            url: "/",
                        },
                        {
                            text: "Misc Fixings & Ironmongery",
                            url: "/",
                        },
                    ],
                },
                {
                    text: "Drywall Products",
                    url: "",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [
                        {
                            text: "Plasterboard",
                            url: "/",
                        },
                        {
                            text: "Plaster",
                            url: "/",
                        },
                    ],
                },
                {
                    text: "Concrete Products",
                    url: "",
                    extraClass: "menu-item-has-children has-mega-menu",
                    subClass: "sub-menu",
                    mega: "true",
                    subMenu: [
                        {
                            text: "Pre-Stressed Lintels",
                            url: "/",
                        },
                        {
                            text: "Copings",
                            url: "/",
                        },
                        {
                            text: "Pier Caps",
                            url: "/",
                        },
                        {
                            text: "Padstones",
                            url: "/",
                        },
                        {
                            text: "Concrete Misc",
                            url: "/",
                        },
                    ],
                },
            ],
        },
    };

    const [open, setOpen] = useState(false);

    const items = JSON.parse(localStorage.getItem('items'))
    const totalQty = items?.reduce((acc, item) => acc + parseFloat(item.price), 0);

    function slugify(str) {
        return str
            .replace(/ &/g, '')
            .replace(' -', '')
            .replace(/\s+/g, '_') // Replace spaces with hyphens
            .replace(/\//g, '')
    }
    return (
        <div>
            <header className="header header--mobile">
                <div className="navigation--mobile align-items-center">
                    <div className="navigation__left">
                        <Link to="/">
                            <a className="ps-logo">
                                <img
                                    src={Logo}
                                    alt="Whanson"
                                    width='100'
                                />
                            </a>
                        </Link>
                    </div>
                    <div className="navigation__right">
                        <div className="ps-cart--mini">
                            <a className="header__extra color-white mobile" >
                                <i className="icon-bag2 "></i>
                                <span>
                                    <i>{items?.length > 0 ? items?.length : 0}</i>
                                </span>
                            </a>
                            <div>
                                <div className="ps-cart__content text-left mobile-cart">
                                    {items?.length > 0 ? (
                                        <>
                                            {items?.map((item, i) => {
                                                return (
                                                    <div className="ps-cart__items p-4" key={i}>
                                                        <div className="ps-product--cart-mobile mb-0">
                                                            <div className="ps-product__thumbnail">
                                                                <Link to={`/product/${item.slug}`}>
                                                                    <img
                                                                        src={item.thumbnail_image != null ? item.thumbnail_image : Wlogo}
                                                                        alt="No Img"
                                                                    />
                                                                </Link>
                                                            </div>
                                                            <div className="ps-product__content">
                                                                <Link className="ps-product__title text-black" to={`/product/${item.slug}`} style={{ color: "black" }} >
                                                                    {item.name}
                                                                </Link>
                                                                <p className="varition-size"> H : {item.height}m , W : {item.width}m , L : {item.length}m </p>
                                                                <p>
                                                                    <small>£{item.price}</small>
                                                                </p>{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <div className="ps-cart__footer">
                                                <h3 className="">
                                                    Sub Total:
                                                    <strong className="color-blue">£{parseFloat(totalQty).toFixed(2)}</strong>
                                                </h3>
                                                <figure className="justify-content-center">
                                                    <Link
                                                        to="/shopping-cart"
                                                        className="btn-ligth-blue w-50 text-center mr-10"
                                                    >
                                                        View Cart
                                                    </Link>
                                                    {!localStorage.getItem("auth_id") &&
                                                        !localStorage.getItem("auth_name") ? null : (
                                                        <a
                                                            className="btn-blue text-center w-50 ml-10"
                                                            onClick={() => setOpen(true)}
                                                        >
                                                            Checkout
                                                        </a>
                                                    )}
                                                </figure>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="ps-cart__items p-4" >
                                            <p className="mb-0">No products in cart</p>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>

                        <Link to="/users/login" className="header__extra color-white mobile mr-0" >
                            <i className="icon-user "></i>
                        </Link>
                        <a className="header__extra color-white mobile" onClick={() => setOpen(true)} >
                            <i className="icon-menu "></i>
                        </a>
                    </div>
                </div>
            </header>
            <Drawer title="Categories" placement="right" className='mobile-drawer' onClose={() => setOpen(false)} open={open}>
                <Menu
                    mode="inline"
                    className="menu--mobile-2"
                >
                    {menuData.menuPrimary.menu_1.map((obj, i) => {
                        return (
                            <SubMenu title={
                                <Link to={`/category/${slugify(obj.text)}`} onClick={() => setOpen(false)} >{obj.text}</Link>
                            } key={i}>
                                {obj.subMenu.map((sub, i) => {
                                    return (
                                        <Menu.Item key={'sub' + i}>
                                            <Link to={`/category/${slugify(sub.text)}`} onClick={() => setOpen(false)}>{sub.text}</Link>
                                        </Menu.Item>

                                    )
                                })}
                            </SubMenu>
                        )
                    })}

                </Menu>
            </Drawer>

        </div >
    )
}

export default HeaderMobile